export default function AddToOrderGuideIcon({
  ariaLabel = "",
  width = "",
  height = "",
  fill = "white",
  style = {},
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        x="0.75"
        y="0.75"
        width="38.5"
        height="38.5"
        rx="4.25"
        fill="white"
        stroke="#878C87"
        strokeWidth="1.5"
      />
      <rect width="30" height="30" transform="translate(5 5)" fill="white" />
      <path
        fill={fill}
        d="M30.6986 29.8695C30.5082 29.6787 30.1992 29.6784 30.0085 29.8689C27.3554 32.5176 23.8449 33.969 20.1025 33.9689C20.0354 33.9689 19.9676 33.9685 19.9003 33.9676C16.2028 33.9159 12.729 32.4282 10.1191 29.7782C7.50932 27.1284 6.07372 23.6323 6.07665 19.9337C6.07899 16.9831 6.98719 14.1612 8.70311 11.7728C8.86038 11.5539 8.8104 11.249 8.59149 11.0917C8.37258 10.9344 8.06766 10.9845 7.9104 11.2034C6.07471 13.7584 5.10305 16.7771 5.10059 19.9329C5.09749 23.8897 6.63282 27.6294 9.4237 30.4631C12.215 33.2971 15.9308 34.8883 19.8867 34.9434C19.9589 34.9444 20.0309 34.945 20.1029 34.945C24.1052 34.9449 27.8606 33.3925 30.6979 30.5596C30.8888 30.3692 30.889 30.0602 30.6986 29.8695Z"
      />
      <path
        fill={fill}
        d="M9.30143 10.1305C9.4918 10.3213 9.80083 10.3216 9.99155 10.1311C12.6446 7.4824 16.1551 6.03098 19.8975 6.03109C19.9646 6.03109 20.0324 6.0315 20.0997 6.03244C23.7972 6.08406 27.271 7.57181 29.8809 10.2218C32.4907 12.8716 33.9263 16.3677 33.9234 20.0663C33.921 23.0169 33.0128 25.8388 31.2969 28.2272C31.1396 28.4461 31.1896 28.751 31.4085 28.9083C31.6274 29.0656 31.9323 29.0155 32.0896 28.7966C33.9253 26.2416 34.8969 23.2229 34.8994 20.0671C34.9025 16.1103 33.3672 12.3706 30.5763 9.53693C27.785 6.70287 24.0692 5.1117 20.1133 5.05656C20.0411 5.05557 19.9691 5.05504 19.8971 5.05504C15.8948 5.0551 12.1394 6.60748 9.30208 9.44043C9.11124 9.6308 9.111 9.93982 9.30143 10.1305Z"
      />
      <rect x="19.5" y="12.5" width="1" height="15" rx="0.5" fill={fill} />
      <rect
        x="12.5"
        y="20.5"
        width="1"
        height="15"
        rx="0.5"
        transform="rotate(-90 12.5 20.5)"
        fill={fill}
      />
    </svg>
  );
}
