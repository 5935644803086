import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { H3 } from "../../Elements";
import LoadingSpinner from "../../LoadingSpinner";
import CustomerStoresAPI from "../../../api/CustomerStoresAPI";
import { useInsertStores } from "../../../api/CustomerStoresAPI";
import CredentialFormInputs from "./CredentialFormInputs";
import CredentialFormButton from "./CredentialFormButton";
import Message from "./Message";

const CredentialInfo = styled(Typography)({
  fontSize: "var(--size-300)",
  fontWeight: 700,
});

export default function CredentialForm({
  store,
  storeUrl,
  toggleReload,
  comingSoon,
  emailRequired,
  removeLabel = false,
  jwtToken,
  setTokenIsExpired,
  customerId,
  formStyles = {},
}) {
  const [defaultValues, setDefaultValues] = useState({
    username: "",
    password: "",
    status: "",
    storeLocation: "",
  });
  const [customerStoreData, setCustomerStoreData] = useState();
  const [savingCredentials, setSavingCredentials] = useState(false);
  const [fadeMsgIn, setFadeMsgIn] = useState(true);
  const [message, setMessage] = useState("");
  const [checkStatus, setCheckStatus] = useState("");

  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    reset,
  } = useForm({ defaultValues: defaultValues });

  const watchUsername = watch("username", false);
  const mutation = useInsertStores(endpoint, customerId, jwtToken, store);

  async function validCredentials(userid, password, store) {
    const formData = {
      uid: userid,
      pwd: password,
      store: store,
    };

    const response = await fetch(
      "https://api.dev.foodmaventools.com/browser-extension/credentialCheck",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
      return "ERROR";
    }

    const data = await response.json();

    if (data && data.status === "SUCCESS") {
      return "SUCCESS";
    }
    if (data && data.status === "FAILURE") {
      if (data.reason === "LOCKED") {
        return "LOCKED";
      } else {
        return "FAILURE";
      }
    } else {
      return "FAILURE";
    }
  }

  useEffect(() => {
    if (customerStoreData && customerStoreData[0]) {
      setDefaultValues({
        username: customerStoreData[0].userid,
        password: customerStoreData[0].password,
        status: customerStoreData[0].status,
        storeLocation: customerStoreData[0].store_location,
      });
      setCheckStatus(customerStoreData[0].status);
    }
  }, [customerStoreData]);

  useEffect(() => {
    reset(defaultValues);
    toggleReload();
  }, [defaultValues]);

  const onSubmit = async (data) => {
    setSavingCredentials(true);
    const validCreds = await validCredentials(
      data.username,
      data.password,
      store
    );
    if (validCreds === "SUCCESS") {
      data.status = "VALID";
      setDefaultValues({
        username: data.username,
        password: data.password,
        status: data.status,
        storeLocation: data.storeLocation,
      });
      setMessage("Credentials updated!");
      setCheckStatus("VALID");
    } else if (validCreds === "LOCKED") {
      data.status = "LOCKED";
      setMessage("Account inaccessible");
      setCheckStatus("LOCKED");
    } else {
      data.status = "INVALID";
      setMessage("Incorrect username or password!");
      setCheckStatus("INVALID");
    }
    mutation.mutate(data);

    setFadeMsgIn(true);
    const timer = setTimeout(() => {
      setFadeMsgIn(false);
    }, 3000);
    setSavingCredentials(false);
  };

  return (
    <>
      {jwtToken && customerId && store && (
        <CustomerStoresAPI
          store={store}
          customerId={customerId}
          jwtToken={jwtToken}
          setTokenIsExpired={setTokenIsExpired}
          setCustomerStoreData={setCustomerStoreData}
        />
      )}
      {customerStoreData ? (
        <>
          {!jwtToken ? (
            <LoadingSpinner />
          ) : (
            <>
              <Message fadeMsgIn={fadeMsgIn} message={message} />
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={formStyles}
              >
                <Box>
                  <H3>{removeLabel ? null : store}</H3>
                  {comingSoon && (
                    <CredentialInfo sx={{ color: "#0000FF" }}>
                      Coming Soon
                    </CredentialInfo>
                  )}
                  {!comingSoon && checkStatus === "VALID" && (
                    <CredentialInfo sx={{ color: "#008000" }}>
                      {checkStatus}
                    </CredentialInfo>
                  )}
                  {!comingSoon && checkStatus !== "VALID" && (
                    <CredentialInfo sx={{ color: "#EE1240" }}>
                      {checkStatus}
                    </CredentialInfo>
                  )}
                </Box>
                <CredentialFormInputs
                  control={control}
                  store={store}
                  storeUrl={storeUrl}
                  password={defaultValues.password}
                  watchUserName={watchUsername}
                  userid={defaultValues.username}
                  storeLocation={defaultValues.storeLocation}
                  customerId={customerId}
                  emailRequired={emailRequired}
                  jwtToken={jwtToken}
                />
                <CredentialFormButton
                  store={store}
                  control={control}
                  reset={reset}
                  defaultValues={defaultValues}
                  savingCredentials={savingCredentials}
                />
              </Box>
            </>
          )}
        </>
      ) : null}
    </>
  );
}
