export default function OrderItemsInfoIcon({
  ariaLabel = "",
  width = "",
  height = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7" fill="#EBEDEB" stroke="#CACDCA" />
      <path
        fill="#656964"
        d="M8.268 5.844V12H6.78V5.844H8.268ZM8.472 4.05C8.472 4.178 8.446 4.298 8.394 4.41C8.342 4.522 8.272 4.62 8.184 4.704C8.1 4.788 8 4.856 7.884 4.908C7.768 4.956 7.644 4.98 7.512 4.98C7.384 4.98 7.262 4.956 7.146 4.908C7.034 4.856 6.936 4.788 6.852 4.704C6.768 4.62 6.7 4.522 6.648 4.41C6.6 4.298 6.576 4.178 6.576 4.05C6.576 3.918 6.6 3.794 6.648 3.678C6.7 3.562 6.768 3.462 6.852 3.378C6.936 3.294 7.034 3.228 7.146 3.18C7.262 3.128 7.384 3.102 7.512 3.102C7.644 3.102 7.768 3.128 7.884 3.18C8 3.228 8.1 3.294 8.184 3.378C8.272 3.462 8.342 3.562 8.394 3.678C8.446 3.794 8.472 3.918 8.472 4.05Z"
      />
    </svg>
  );
}
