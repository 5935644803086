import { useEffect, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useMediaQuery from '@mui/material/useMediaQuery'
import SideDrawer from "../SideDrawer";
import TopNav from "../TopNav";

const initialState = {
  orderGuide: true,
  credentials: false,
  download: false,
  profile: false,
};

const isSelectedReducer = (state, action) => {
  switch (action.type) {
    case "orderGuide":
      return {
        orderGuide: true,
        credentials: false,
        download: false,
        profile: false,
      };
    case "credentials":
      return {
        orderGuide: false,
        credentials: true,
        download: false,
        profile: false,
      };
    case "download":
      return {
        orderGuide: false,
        credentials: false,
        download: true,
        profile: false,
      };
    case "profile":
      return {
        orderGuide: false,
        credentials: false,
        download: false,
        profile: true,
      };
    default:
      return {
        orderGuide: true,
        credentials: false,
        download: false,
        profile: false,
      };
  }
};

export default function Home({ customerId }) {
  const { user } = useAuthenticator();
  const [cookies, setCookie] = useCookies(["fmLogin"]);
  const isMobile = useMediaQuery("(max-width:1100px)");
  const [isSelected, setIsSelected] = useReducer(
    isSelectedReducer,
    initialState
  );

  useEffect(() => {
    if (user.username) {
      setCookie("fmLogin", customerId, {
        path: "/",
        maxAge: 31536000,
      });
    }
  }, [customerId]);

  let flexDirection;
  if (isMobile) {
    flexDirection = "column";
  } else {
    flexDirection = "row";
  }

  return (
    <Box sx={{ display: "flex", flexDirection: flexDirection }}>
      {isMobile ? (
        <TopNav isSelected={isSelected} setIsSelected={setIsSelected} />
      ) : (
        <SideDrawer isSelected={isSelected} setIsSelected={setIsSelected} />
      )}
      <Divider />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Outlet context={{ setIsSelected }}/>
      </Box>
    </Box>
  );
}
