import { useState, useEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from "@mui/system";
import { Alert, AlertTitle, Box, Stack, Typography } from "@mui/material";
import { H1, H2 } from "../Elements";
import CredentialAccordion from "./CredentialAccordion";
import CredentialForm from "./CredentialForm/CredentialForm";
import SuggestionForm from "./SuggestionForm";

const Wrapper = styled("div")({
  paddingRight: "16px",
  paddingLeft: "16px",
  marginRight: "auto",
  marginLeft: "auto",
});

const List = styled("ul")({
  maxWidth: "100%",
});

const ListItem = styled("li")({
  marginTop: "8px",
  color: "#656964",
});

const Text = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

const formStyles = {
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "160px repeat(3, 1fr) 128px",
  alignItems: "center",
  marginTop: "16px",
};

const suggestionFormStyles = {
  display: "grid",
  gap: "16px",
  gridTemplateColumns: "160px 1fr 128px",
  alignItems: "center",
  marginTop: "16px",
};

const accordionFormStyles = {
  display: "grid",
  gap: "16px",
  alignItems: "center",
  width: "80%",
};

export default function Credentials({ customerId, jwtToken }) {
  const [reload, setReload] = useState(false);
  const [tokenIsExpired, setTokenIsExpired] = useState(false);
  const toggleReload = () => setReload((value) => !value);
  const isMobile = useMediaQuery("(max-width:1500px)");

  useEffect(() => {
    document.title = "Vendor Credentials";
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <H1>Vendor Credentials</H1>
      {tokenIsExpired ? (
        <Stack sx={{ width: "50%", margin: "1rem 0"}}>
          <Alert variant="filled" severity="error">
            <AlertTitle>Session Expired</AlertTitle>
            Your session has expired - please refresh your browser
          </Alert>
        </Stack>
      ) : null}
      <Wrapper>
        <Box>
          <H2>Setup Tips</H2>
          <List>
            <ListItem>
              <Text>
                Your account isn’t fully set up until you have added at least
                one vendor login.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Your Smart Shopping Assistant uses your vendor credentials to
                scan your products and prices to find better deals on comparable
                products as you shop.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Add the credentials below for all of the vendors you use.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                Once you’re finished, make sure to click the FoodMaven leaf in
                the top right corner of your browser to sign in.
              </Text>
            </ListItem>
            <ListItem>
              <Text>
                It may take your Smart Shopping Assistant up to one day to start
                showing you better deals.
              </Text>
            </ListItem>
          </List>
        </Box>
        <Box>
          <H2>Vendors</H2>
          {customerId && jwtToken ? (
            <>
              {isMobile ? (
                <>
                  <CredentialAccordion label="Sysco">
                    <CredentialForm
                      key="Sysco"
                      store="Sysco"
                      storeUrl="https://shop.sysco.com/auth/login"
                      removeLabel={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      emailRequired={true}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="US Foods">
                    <CredentialForm
                      key="US Foods"
                      store="US Foods"
                      storeUrl="https://usfoods.com/order"
                      removeLabel={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="Restaurant Depot">
                    <CredentialForm
                      key="Restaurant Depot"
                      store="Restaurant Depot"
                      storeUrl="https://member.restaurantdepot.com/customer/account/login/"
                      removeLabel={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      emailRequired={true}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="Shamrock">
                    <CredentialForm
                      key="Shamrock"
                      store="Shamrock"
                      storeUrl="https://shamrockorders.com"
                      removeLabel={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      emailRequired={true}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="What Chefs Want">
                    <CredentialForm
                      key="What Chefs Want"
                      store="What Chefs Want"
                      storeUrl="https://www.whatchefswant.com/customer-login/"
                      removeLabel={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="PFG">
                    <CredentialForm
                      key="PFG"
                      store="PFG"
                      storeUrl="https://eastern5.onlinefoodservice.com/pnet/eOrderServlet"
                      removeLabel={true}
                      comingSoon={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="Ben E Keith">
                    <CredentialForm
                      key="Ben E Keith"
                      store="Ben E Keith"
                      storeUrl="https://www.benekeith.com/member/login/"
                      removeLabel={true}
                      comingSoon={true}
                      toggleReload={toggleReload}
                      customerId={customerId}
                      jwtToken={jwtToken}
                      setTokenIsExpired={setTokenIsExpired}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                  <CredentialAccordion label="Suggest a Vendor">
                    <SuggestionForm
                      customerId={customerId}
                      jwtToken={jwtToken}
                      toggleReload={toggleReload}
                      removeLabel={true}
                      isAccordion={true}
                      formStyles={accordionFormStyles}
                    />
                  </CredentialAccordion>
                </>
              ) : (
                <>
                  <CredentialForm
                    key="Sysco"
                    store="Sysco"
                    storeUrl="https://shop.sysco.com/auth/login"
                    toggleReload={toggleReload}
                    customerId={customerId}
                    emailRequired={true}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="US Foods"
                    store="US Foods"
                    storeUrl="https://usfoods.com/order"
                    toggleReload={toggleReload}
                    customerId={customerId}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="Restaurant Depot"
                    store="Restaurant Depot"
                    storeUrl="https://member.restaurantdepot.com/customer/account/login/"
                    toggleReload={toggleReload}
                    customerId={customerId}
                    emailRequired={true}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="Shamrock"
                    store="Shamrock"
                    storeUrl="https://shamrockorders.com"
                    toggleReload={toggleReload}
                    customerId={customerId}
                    emailRequired={true}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="What Chefs Want"
                    store="What Chefs Want"
                    storeUrl="https://www.whatchefswant.com/customer-login/"
                    toggleReload={toggleReload}
                    customerId={customerId}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="PFG"
                    store="PFG"
                    storeUrl="https://eastern5.onlinefoodservice.com/pnet/eOrderServlet"
                    comingSoon={true}
                    toggleReload={toggleReload}
                    customerId={customerId}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <CredentialForm
                    key="Ben E Keith"
                    store="Ben E Keith"
                    storeUrl="https://www.benekeith.com/member/login/"
                    comingSoon={true}
                    toggleReload={toggleReload}
                    customerId={customerId}
                    jwtToken={jwtToken}
                    setTokenIsExpired={setTokenIsExpired}
                    formStyles={formStyles}
                  />
                  <SuggestionForm
                    customerId={customerId}
                    jwtToken={jwtToken}
                    toggleReload={toggleReload}
                    formStyles={suggestionFormStyles}
                  />
                </>
              )}
            </>
          ) : (
            <Box>Waiting on token and customerId</Box>
          )}
        </Box>
      </Wrapper>
    </Box>
  );
}
