import { GraphQLClient } from "graphql-request";

const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

export default async function validCredentialsCheck(jwt, customerId) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + jwt,
    },
  });

  const variables = {
    customer_id: customerId,
  };

  const query = `
    query CheckCredentials($customer_id: uuid!) {
      customer_stores(where: {customer_id: { _eq: $customer_id }}) {
        store
        status
      }
    }
  `;

  try {
    const data = await client.request(query, variables);
    return data.customer_stores;
  } catch {
    console.log("error");
  }
}
