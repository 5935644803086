import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Text } from "../../components/Elements";
import validCredentialsCheck from "../../api/ValidCredentialsCheck";
import getPurchaseHistory from "../../api/PurchaseHistoryAPI";
import OrderItemsInfoIcon from "../../components/Icons/OrderItemsInfoIcon";
import PurchaseHistoryItem from "./PurchaseHistoryItem";
import DesktopHeader from "./DesktopHeader";
import ReturnButton from "./ReturnButton";
import Sort from "./Sort";

const Wrapper = styled("div")({
  maxWidth: "1100px",
  marginRight: "auto",
  marginLeft: "auto",
});

const TableHeaders = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 3fr 1fr 1fr 1fr",
  gap: "16px",
});

const TableHeader = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: "1.5rem",
    color: theme.palette.grey.dark,
  })
);

export default function AddToOrderGuide({ customerId }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { user } = useAuthenticator((context) => [context.user]);
  const [customerStores, setCustomerStores] = useState([]);
  const [sortState, setSortState] = useState({
    items: [],
    text: "Sort By",
    isSorted: false,
  });

  useEffect(() => {
    if (customerId) {
      const jwt = user.signInUserSession.idToken.jwtToken;

      Promise.all([
        validCredentialsCheck(jwt, customerId),
        getPurchaseHistory(jwt, customerId),
      ]).then((data) => {
        setSortState({
          items: makeUnique(data[1]),
          text: "Sort By",
          isSorted: false,
        });
        setCustomerStores(data[0]);
      });
    }
  }, [customerId]);

  const makeUnique = (data) => {
    const storeProductIds = data.map((item) => item.storeProductId);
    const unique = data.filter(
      ({ storeProductId }, index) =>
        !storeProductIds.includes(storeProductId, index + 1)
    );

    return unique;
  };

  return (
    <Wrapper>
      <DesktopHeader
        title="Add Items"
        firstButton={<ReturnButton />}
        secondButton={null}
      />
      <Box sx={{ paddingBottom: "32px" }}>
        <Sort
          items={sortState.items}
          sortState={sortState}
          setSortState={setSortState}
          includePriceSorting={false}
        />
      </Box>
      <OrderHistoryTitle />
      {isMobile ? null : (
        <TableHeaders>
          <TableHeader>Item #</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader sx={{ textAlign: "center" }}>Size</TableHeader>
          <TableHeader sx={{ textAlign: "center" }}>Vendor</TableHeader>
        </TableHeaders>
      )}
      <Divider sx={{ borderColor: "#CACDCA" }} />
      {sortState.items.map((item) => (
        <PurchaseHistoryItem
          key={item.id}
          customerId={item.cid}
          purchaseHistoryId={item.id}
          name={item.name}
          rawPrice={item.rawPrice}
          rawSize={item.rawSize}
          store={item.store}
          storeProductId={item.storeProductId}
          customerStores={customerStores}
          isMobile={isMobile}
        />
      ))}
    </Wrapper>
  );
}

function OrderHistoryTitle() {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Text sx={{ fontSize: "1.5rem", paddingBottom: "16px" }}>
        Order History Items
      </Text>
      <Tooltip
        arrow
        placement="top"
        title="FoodMaven scans your order history so you can easily add items you already buy to your order guide."
      >
        <IconButton sx={{ display: "block" }}>
          <OrderItemsInfoIcon width="15" height="auto" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
