import { useEffect } from "react";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { request, gql } from "graphql-request";
import Box from "@mui/material/Box";
import LoadingSpinner from "../components/LoadingSpinner";

function VendorSuggestAPI({ setSuggest, jwtToken, customerId }) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const { status, data } = useVendorSuggest(endpoint, customerId, jwtToken);

  useEffect(() => {
    if (data && data[0]) {
      setSuggest(data[0].vendor_suggest);
    }
  }, [data]);

  return (
    <>
      {status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}
export function useInsertSuggest(endpoint, customerId, jwtToken) {
  return useMutation(async (formData) => {
    let variables = {
      customerId: customerId,
      suggest: formData.suggest || "",
    };

    const requestHeaders = {
      authorization: "Bearer " + jwtToken,
    };

    const data = await request(
      endpoint,
      gql`
        mutation UpdateSuggest($customerId: uuid!, $suggest: String!) {
          insert_suggest(
            objects: { customer_id: $customerId, vendor_suggest: $suggest }
            on_conflict: {
              constraint: suggest_pkey
              update_columns: [vendor_suggest]
              where: { customer_id: { _eq: $customerId } }
            }
          ) {
            returning {
              customer_id
              vendor_suggest
            }
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data.insert_suggest;
  });
}

function useVendorSuggest(endpoint, customerId, jwtToken) {
  let variables = { customerId: customerId };
  const requestHeaders = {
    authorization: "Bearer " + jwtToken,
  };

  return useQuery(
    ["vendorSuggest", customerId],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query getSuggest($customerId: uuid!) {
            suggest(where: { customer_id: { _eq: $customerId } }) {
              customer_id
              vendor_suggest
            }
          }
        `,
        variables,
        requestHeaders
      );
      return data.suggest;
    },
    {
      cacheTime: 0,
    }
  );
}
export default VendorSuggestAPI;
