export default function RemoveFromOrderGuideIcon({
  ariaLabel = "",
  width = "",
  height = "",
  enabled = true,
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      {enabled ? (
        <>
          <g filter="url(#filter0_d_518_893)">
            <rect x="2" width="40" height="40" rx="5" fill="white" />
            <rect
              x="2.75"
              y="0.75"
              width="38.5"
              height="38.5"
              rx="4.25"
              fill="white"
              stroke="#878C87"
              strokeWidth="1.5"
            />
          </g>
          <path
            fill="#878C87"
            d="M26.7826 16.5334L25.6895 29.4902C25.6406 30.0703 26.5159 30.1495 26.5653 29.5641L27.6584 16.6073C27.6788 16.3655 27.4993 16.1529 27.2574 16.1324C27.0153 16.112 26.8029 16.2915 26.7826 16.5334Z"
          />
          <path
            fill="#878C87"
            d="M16.7404 16.1324C16.4986 16.1529 16.3191 16.3654 16.3395 16.6073L17.4326 29.5641C17.482 30.1502 18.3573 30.0703 18.3084 29.4902L17.2153 16.5334C17.1949 16.2915 16.9822 16.1121 16.7404 16.1324Z"
          />
          <path
            fill="#878C87"
            d="M21.5605 16.5703V29.5271C21.5605 29.7698 21.7572 29.9666 22 29.9666C22.2428 29.9666 22.4395 29.7698 22.4395 29.5271V16.5703C22.4395 16.3276 22.2428 16.1309 22 16.1309C21.7572 16.1309 21.5605 16.3276 21.5605 16.5703Z"
          />
          <path
            fill="#878C87"
            d="M33.0955 8.39445H26.185V6.84986C26.185 5.82986 25.3552 5 24.3351 5H19.6643C18.6443 5 17.8144 5.82986 17.8144 6.84986V8.39445H10.9039C9.78627 8.39445 8.87695 9.30377 8.87695 10.4214C8.87695 11.5391 9.78627 12.4484 10.9039 12.4484H11.3766L13.0206 31.9346C13.1668 33.6665 14.6149 35 16.3556 35H27.6439C29.3819 35 30.8324 33.6692 30.9788 31.9346L31.2067 29.233C31.2271 28.9912 31.0476 28.7786 30.8058 28.7582C30.5633 28.7374 30.3513 28.9173 30.3309 29.1591L30.103 31.8607C29.9961 33.1282 28.9159 34.1211 27.6439 34.1211H16.3556C15.0836 34.1211 14.0034 33.1282 13.8964 31.8607L12.2586 12.4484H13.5598C13.8025 12.4484 13.9992 12.2517 13.9992 12.009C13.9992 11.7663 13.8025 11.5695 13.5598 11.5695C12.5971 11.5695 11.462 11.5695 10.9039 11.5695C10.2709 11.5695 9.75586 11.0545 9.75586 10.4214C9.75586 9.7884 10.2709 9.27336 10.9039 9.27336H33.0955C33.7286 9.27336 34.2436 9.7884 34.2436 10.4214C34.2436 11.0545 33.7286 11.5695 33.0955 11.5695C32.4129 11.5695 17.0913 11.5695 15.6154 11.5695C15.3727 11.5695 15.176 11.7663 15.176 12.009C15.176 12.2517 15.3727 12.4484 15.6154 12.4484H31.7408L30.5039 27.1089C30.4835 27.3507 30.663 27.5633 30.9049 27.5837C30.9175 27.5848 30.9299 27.5853 30.9424 27.5853C31.1683 27.5853 31.3604 27.4121 31.3798 27.1828L32.6229 12.4484H33.0955C34.2132 12.4484 35.1226 11.5391 35.1226 10.4214C35.1226 9.30377 34.2131 8.39445 33.0955 8.39445ZM25.3061 8.39445H18.6933V6.84986C18.6933 6.31449 19.1289 5.87891 19.6643 5.87891H24.3351C24.8705 5.87891 25.3061 6.31449 25.3061 6.84986L25.3061 8.39445Z"
          />
          <defs>
            <filter
              id="filter0_d_518_893"
              x="0"
              y="0"
              width="44"
              height="44"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_518_893"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_518_893"
                result="shape"
              />
            </filter>
          </defs>
        </>
      ) : (
        <>
          <rect
            x="0.75"
            y="0.75"
            width="38.5"
            height="38.5"
            rx="4.25"
            fill="white"
            stroke="#CACDCA"
            strokeWidth="1.5"
          />
          <path
            fill="#CACDCA"
            d="M24.7836 16.5333L23.6904 29.4901C23.6416 30.0702 24.5169 30.1494 24.5663 29.564L25.6594 16.6072C25.6798 16.3653 25.5002 16.1528 25.2584 16.1323C25.0163 16.1119 24.8039 16.2914 24.7836 16.5333V16.5333Z"
          />
          <path
            fill="#CACDCA"
            d="M14.7414 16.1323C14.4996 16.1527 14.3201 16.3653 14.3404 16.6071L15.4336 29.564C15.483 30.1501 16.3582 30.0702 16.3094 29.4901L15.2162 16.5333C15.1959 16.2914 14.9832 16.112 14.7414 16.1323V16.1323Z"
          />
          <path
            fill="#CACDCA"
            d="M19.5605 16.5702V29.527C19.5605 29.7697 19.7572 29.9665 20 29.9665C20.2428 29.9665 20.4395 29.7697 20.4395 29.527V16.5702C20.4395 16.3275 20.2428 16.1307 20 16.1307C19.7572 16.1307 19.5605 16.3275 19.5605 16.5702Z"
          />
          <path
            fill="#CACDCA"
            d="M31.0955 8.39445H24.185V6.84986C24.185 5.82986 23.3552 5 22.3351 5H17.6643C16.6443 5 15.8144 5.82986 15.8144 6.84986V8.39445H8.90395C7.78627 8.39445 6.87695 9.30377 6.87695 10.4214C6.87695 11.5391 7.78627 12.4484 8.90395 12.4484H9.37662L11.0206 31.9346C11.1668 33.6665 12.6149 35 14.3556 35H25.6439C27.3819 35 28.8324 33.6692 28.9788 31.9346L29.2067 29.233C29.2271 28.9912 29.0476 28.7786 28.8058 28.7582C28.5633 28.7374 28.3513 28.9173 28.3309 29.1591L28.103 31.8607C27.9961 33.1282 26.9159 34.1211 25.6439 34.1211H14.3556C13.0836 34.1211 12.0034 33.1282 11.8964 31.8607L10.2586 12.4484H11.5598C11.8025 12.4484 11.9992 12.2517 11.9992 12.009C11.9992 11.7663 11.8025 11.5695 11.5598 11.5695C10.5971 11.5695 9.46199 11.5695 8.90395 11.5695C8.2709 11.5695 7.75586 11.0545 7.75586 10.4214C7.75586 9.7884 8.2709 9.27336 8.90395 9.27336H31.0955C31.7286 9.27336 32.2436 9.7884 32.2436 10.4214C32.2436 11.0545 31.7286 11.5695 31.0955 11.5695C30.4129 11.5695 15.0913 11.5695 13.6154 11.5695C13.3727 11.5695 13.176 11.7663 13.176 12.009C13.176 12.2517 13.3727 12.4484 13.6154 12.4484H29.7408L28.5039 27.1089C28.4835 27.3507 28.663 27.5633 28.9049 27.5837C28.9175 27.5848 28.9299 27.5853 28.9424 27.5853C29.1683 27.5853 29.3604 27.4121 29.3798 27.1828L30.6229 12.4484H31.0955C32.2132 12.4484 33.1226 11.5391 33.1226 10.4214C33.1226 9.30377 32.2131 8.39445 31.0955 8.39445ZM23.3061 8.39445H16.6933V6.84986C16.6933 6.31449 17.1289 5.87891 17.6643 5.87891H22.3351C22.8705 5.87891 23.3061 6.31449 23.3061 6.84986L23.3061 8.39445Z"
          />
        </>
      )}
    </svg>
  );
}
