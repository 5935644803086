import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Text } from "../../components/Elements";
import { sortItems } from "../../helpers/data.helpers";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SortItemMobile from "./SortItemMobile";

const SortButton = styled((props) => (
  <button id="sort-button" aria-haspopup="true" {...props}></button>
))(({ theme }) => ({
  display: "flex",
  border: "1.5px solid",
  borderColor: theme.palette.grey.main,
  borderRadius: "16px",
  cursor: "pointer",
  padding: "4px 8px",
}));

const SortTextContainer = styled("span")({
  paddingRight: "8px",
});

const StyledListItem = styled((props) => <ListItem {...props} />)({
  cursor: "pointer",
});

const StyledMenuItem = styled((props) => <MenuItem {...props} />)(
  ({ theme }) => ({
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: theme.palette.grey.main,
    },
  })
);

export default function Sort({
  items,
  memoizedItems,
  sortState,
  setSortState,
  includePriceSorting = true,
}) {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetSort = (event) => {
    event.stopPropagation();
    handleClose();
    setSortState({
      items: memoizedItems,
      text: "Sort By",
      isSorted: false,
      selectedSort: null,
    });
  };

  const itemNameAZ = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Item Name A to Z",
      isSorted: true,
      selectedSort: "itemNameAZ",
    });

    function compare(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    }
  };

  const itemNameZA = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Item Name Z to A",
      isSorted: true,
      selectedSort: "itemNameZA",
    });

    function compare(a, b) {
      if (a.name < b.name) {
        return 1;
      }
      if (a.name > b.name) {
        return -1;
      }
      return 0;
    }
  };

  const itemNumberLowHigh = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Item # Low to High",
      isSorted: true,
      selectedSort: "itemNumberLowHigh",
    });

    function compare(a, b) {
      const ogCompProductIdA = parseInt(a.storeProductId || a.productId);
      const ogCompProductIdB = parseInt(b.storeProductId || b.productId);

      if (ogCompProductIdA < ogCompProductIdB) {
        return -1;
      }
      if (ogCompProductIdA > ogCompProductIdB) {
        return 1;
      }
      return 0;
    }
  };

  const itemNumberHighLow = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Item # High to Low",
      isSorted: true,
      selectedSort: "itemNumberHighLow",
    });

    function compare(a, b) {
      const ogCompProductIdA = parseInt(a.storeProductId || a.productId);
      const ogCompProductIdB = parseInt(b.storeProductId || b.productId);

      if (ogCompProductIdA < ogCompProductIdB) {
        return 1;
      }
      if (ogCompProductIdA > ogCompProductIdB) {
        return -1;
      }
      return 0;
    }
  };

  const vendorAZ = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Vendor A to Z",
      isSorted: true,
      selectedSort: "vendorAZ",
    });

    function compare(a, b) {
      if (a.store < b.store) {
        return -1;
      }
      if (a.store > b.store) {
        return 1;
      }
      return 0;
    }
  };

  const vendorZA = () => {
    handleClose();
    setSortState({
      items: items.sort(compare),
      text: "Sort By: Vendor Z to A",
      isSorted: true,
      selectedSort: "vendorZA",
    });

    function compare(a, b) {
      if (a.store < b.store) {
        return 1;
      }
      if (a.store > b.store) {
        return -1;
      }
      return 0;
    }
  };

  const priceLowHigh = () => {
    handleClose();
    setSortState({
      items: sortItems(items, compare),
      text: "Sort By: Price Low to High",
      isSorted: true,
      selectedSort: "priceLowHigh",
    });

    function compare(a, b) {
      let compOne = parseFloat(a.rawPrice.split(" ")[0].split("$")[1]);
      let compTwo = parseFloat(b.rawPrice.split(" ")[0].split("$")[1]);

      if (compOne < compTwo) {
        return -1;
      }
      if (compOne > compTwo) {
        return 1;
      }
      return 0;
    }
  };

  const priceHighLow = () => {
    handleClose();
    setSortState({
      items: sortItems(items, compare),
      text: "Sort By: Price High to Low",
      isSorted: true,
      selectedSort: "priceHighLow",
    });

    function compare(a, b) {
      let compOne = parseFloat(a.rawPrice.split(" ")[0].split("$")[1]);
      let compTwo = parseFloat(b.rawPrice.split(" ")[0].split("$")[1]);

      if (compOne < compTwo) {
        return 1;
      }
      if (compOne > compTwo) {
        return -1;
      }
      return 0;
    }
  };

  return (
    <>
      <SortButton
        onClick={handleClick}
        aria-controls={open ? "sort-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        sx={{
          color: sortState.isSorted ? "#FFFFFF" : "#878C87",
          background: sortState.isSorted ? "#878C87" : "#FFFFFF",
        }}
      >
        {isMobile ? (
          <>
            <SortTextContainer>Sort By</SortTextContainer>
            {sortState.isSorted ? <div>(1)</div> : <KeyboardArrowDownIcon />}
          </>
        ) : (
          <>
            <SortTextContainer>{sortState.text}</SortTextContainer>
            {sortState.isSorted ? (
              <CloseIcon onClick={resetSort} />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </>
        )}
      </SortButton>
      <>
        {isMobile ? (
          <Drawer
            anchor="bottom"
            open={open}
            onClose={handleClose}
            PaperProps={{ sx: { borderRadius: "16px" } }}
          >
            <List>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text sx={{ fontSize: "var(--size-600)", color: "#878C87" }}>
                  Sort By
                </Text>
                <Text
                  onClick={handleClose}
                  sx={{ color: "#3E92CC", cursor: "pointer" }}
                >
                  close
                </Text>
              </ListItem>
              <Divider />
              <StyledListItem onClick={itemNameAZ}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="itemNameAZ"
                  itemText="Item Name A to Z"
                />
              </StyledListItem>
              <Divider />
              <StyledListItem onClick={itemNameZA}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="itemNameZA"
                  itemText="Item Name Z to A"
                />
              </StyledListItem>
              <Divider />
              <StyledListItem onClick={itemNumberLowHigh}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="itemNumberLowHigh"
                  itemText="Item # Low to High"
                />
              </StyledListItem>
              <Divider />
              <StyledListItem onClick={itemNumberHighLow}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="itemNumberHighLow"
                  itemText="Item # High to Low"
                />
              </StyledListItem>
              <Divider />
              <StyledListItem onClick={vendorAZ}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="vendorAZ"
                  itemText="Vendor A to Z"
                />
              </StyledListItem>
              <Divider />
              <StyledListItem onClick={vendorZA}>
                <SortItemMobile
                  sortState={sortState}
                  selectedSort="vendorZA"
                  itemText="Vendor Z to A"
                />
              </StyledListItem>
              <Divider />
              {includePriceSorting ? (
                <>
                  <StyledListItem onClick={priceLowHigh}>
                    <SortItemMobile
                      sortState={sortState}
                      selectedSort="priceLowHigh"
                      itemText="Price Low to High"
                    />
                  </StyledListItem>
                  <Divider />
                  <StyledListItem onClick={priceHighLow}>
                    <SortItemMobile
                      sortState={sortState}
                      selectedSort="priceHighLow"
                      itemText="Price High to Low"
                    />
                  </StyledListItem>
                  <Divider />
                </>
              ) : null}
              <StyledListItem onClick={resetSort} sx={{ color: "#3E92CC" }}>
                Clear Sorting
              </StyledListItem>
            </List>
          </Drawer>
        ) : (
          <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "sort-menu-button",
            }}
          >
            <StyledMenuItem onClick={itemNameAZ}>
              Item Name A to Z
            </StyledMenuItem>
            <StyledMenuItem onClick={itemNameZA}>
              Item Name Z to A
            </StyledMenuItem>
            <StyledMenuItem onClick={itemNumberLowHigh}>
              Item # Low to High
            </StyledMenuItem>
            <StyledMenuItem onClick={itemNumberHighLow}>
              Item # High to Low
            </StyledMenuItem>
            <StyledMenuItem onClick={vendorAZ}>Vendor A to Z</StyledMenuItem>
            <StyledMenuItem onClick={vendorZA}>Vendor Z to A</StyledMenuItem>
            {includePriceSorting ? (
              <div>
                <StyledMenuItem onClick={priceLowHigh}>
                  Price Low to High
                </StyledMenuItem>
                <StyledMenuItem onClick={priceHighLow}>
                  Price High to Low
                </StyledMenuItem>
              </div>
            ) : null}
          </Menu>
        )}
      </>
    </>
  );
}
