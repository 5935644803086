import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { H3, StyledButton, StyledTextField } from "../Elements";
import LoadingSpinner from "../LoadingSpinner";
import { useInsertSuggest } from "../../api/VendorSuggestAPI";
import VendorSuggestAPI from "../../api/VendorSuggestAPI";

const HelperText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    color: theme.palette.grey.main,
    fontSize: "var(--size-300)",
  })
);

export default function SuggestionForm({
  jwtToken,
  customerId,
  toggleReload,
  removeLabel = false,
  isAccordion = false,
  formStyles = {},
}) {
  const [suggest, setSuggest] = useState();
  const [fadeMsgIn, setFadeMsgIn] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    suggest: "",
  });
  const { register, handleSubmit, reset } = useForm({
    defaultValues: defaultValues,
  });

  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const mutation = useInsertSuggest(endpoint, customerId, jwtToken);

  useEffect(() => {
    if (suggest) {
      // setSuggest(suggest[0].suggest);
      setDefaultValues({
        // suggest: suggest,
        suggest: "",
      });
    }
  }, [suggest]);

  useEffect(() => {
    reset(defaultValues);
    toggleReload();
  }, [defaultValues]);

  const onSubmit = (data) => {
    setFadeMsgIn(true);
    setSuggest(data.suggest);
    mutation.mutate(data);
    setDefaultValues({
      // suggest: data.suggest,
      suggest: "",
    });

    const timer = setTimeout(() => {
      setFadeMsgIn(false);
    }, 3000);
  };

  return (
    <>
      {jwtToken && customerId && (
        <VendorSuggestAPI
          setSuggest={setSuggest}
          jwtToken={jwtToken}
          customerId={customerId}
        />
      )}
      {customerId ? (
        <>
          {mutation.isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={formStyles}
              >
                <H3>{removeLabel ? null : "Suggest a Vendor"}</H3>
                {isAccordion ? (
                  <HelperText>Enter vendor names separated by comma</HelperText>
                ) : null}
                <StyledTextField
                  required
                  id="suggest"
                  name="suggest"
                  {...register("suggest")}
                />
                <StyledButton
                  id="save"
                  variant="contained"
                  type="submit"
                  sx={{ height: "40px" }}
                >
                  Submit
                </StyledButton>
              </Box>
              {isAccordion ? null : (
                <HelperText sx={{ marginLeft: "176px" }}>
                  Enter vendor names separated by comma
                </HelperText>
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
}
