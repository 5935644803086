import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import DesktopHeader from "./DesktopHeader";

const Wrapper = styled("div")({
  maxWidth: "65ch",
  paddingRight: "16px",
  paddingLeft: "16px",
  marginRight: "auto",
  marginLeft: "auto",
});

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
});

export default function NoPurchaseHistory() {
  return (
    <Wrapper>
      <DesktopHeader
        title="Order Guide"
        firstButton={null}
        secondButton={null}
      />
      <Container>
        <Text sx={{ fontSize: "var(--size-500)" }}>
          We’re working on getting your order history from your vendors to use
          in your order guide. This can take up to one day.
        </Text>
        <Text sx={{ fontSize: "var(--size-500)", paddingTop: "32px" }}>
          While you wait,{" "}
          <a href="https://www.foodmaven.com/order-guide" target="_blank">
            check out our guide
          </a>{" "}
          on how to set up and use your order guide.
        </Text>
      </Container>
    </Wrapper>
  );
}
