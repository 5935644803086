//
//  Vendor/store names come in from our database all lowercase.
//  This function allows us to stylize the names to be displayed
//  appropriately.
//
function transformVendorName(vendor) {
  switch (vendor) {
    case "us foods":
      return "US Foods";
    default:
      return capitalize(vendor);
  }
}

function capitalize(text) {
  return text
    .split(" ")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}

//
//  Take a customer's vendors/stores, compare them with the
//  current active vendors and then return an object which is used
//  in a state hook to let a user hide/show product matches from
//  a vendor in their order guide.
//
function transformStores(activeVendors, stores) {
  let vendors = {};

  stores
    .map((obj) => obj.store.toLowerCase())
    .sort()
    .filter((vendor) => activeVendors.includes(vendor))
    .forEach((vendor) => {
      vendors[vendor] = { display: true, isSelectedOnMobile: true };
    });

  return vendors;
}

//
//  Sort the items array, but put any items that we do not have
//  pricing information for at the bottom.
//
function sortItems(items, sortFunction) {
  let itemsWithPricing = [];
  let itemsWithNoPricing = [];

  items.forEach((item) => {
    if (Object.keys(item.matches).length === 0) {
      itemsWithNoPricing.push(item);
    } else {
      itemsWithPricing.push(item);
    }
  });

  return [...itemsWithPricing.sort(sortFunction), ...itemsWithNoPricing];
}

//
//  Determine if the user's credentials for a vendor have
//  become invalid.
//
function hasInvalidVendorCredentials(customerStores, vendor) {
  const store = customerStores.find(
    (obj) => obj.store.toLowerCase() === vendor.toLowerCase()
  );

  return store.status.toUpperCase() === "INVALID";
}

export {
  transformVendorName,
  transformStores,
  sortItems,
  hasInvalidVendorCredentials,
};
