import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import DesktopHeader from "./DesktopHeader";

const Wrapper = styled("div")({
  maxWidth: "55ch",
  paddingRight: "16px",
  paddingLeft: "16px",
  marginRight: "auto",
  marginLeft: "auto",
});

const Container = styled("div")({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
});

export default function NoCredentials({ updateSelected }) {
  return (
    <Wrapper>
      <DesktopHeader
        title="Order Guide"
        firstButton={null}
        secondButton={null}
      />
      <Container>
        <Text sx={{ fontSize: "var(--size-700)" }}>
          To start building your order guide,{" "}
          <Link to="/credentials" onClick={updateSelected}>
            add at least one vendor to your account.
          </Link>
        </Text>
      </Container>
    </Wrapper>
  );
}
