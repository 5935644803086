import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";

export default function Message({ fadeMsgIn, message }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <p>{null}</p>
      {message ? (
        <Fade timeout={{ exit: 3000 }} in={fadeMsgIn}>
          <Typography
            sx={{
              color: "#EE1240",
            }}
          >
            {message}
          </Typography>
        </Fade>
      ) : (
        <p>{null}</p>
      )}
    </Box>
  );
}
