import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { styled } from "@mui/system";
import { Box, Divider, Typography } from "@mui/material";
import { StyledButton } from "../../components/Elements";
import {
  transformVendorName,
  hasInvalidVendorCredentials,
} from "../../helpers/data.helpers";
import { insertOrderGuideItem } from "../../api/OrderGuideAPI";
import InvalidVendor from "./InvalidVendor";

const MobileContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "80px",
  alignItems: "center",
  justifyItems: "end",
  padding: "16px 0",
});

const DesktopContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 3fr 1fr 1fr 1fr",
  gap: "16px",
  alignItems: "center",
  padding: "16px 0",
});

const Text = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

const TextLight = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

export default function PurchaseHistoryItem({
  customerId,
  purchaseHistoryId,
  name,
  rawPrice,
  rawSize,
  store,
  storeProductId,
  customerStores = [],
  isMobile = false,
}) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [clicked, setClicked] = useState(false);

  const addItem = () => {
    const jwt = user.signInUserSession.idToken.jwtToken;

    insertOrderGuideItem(
      jwt,
      customerId,
      purchaseHistoryId,
      name,
      rawPrice,
      rawSize,
      store,
      storeProductId
    );

    setClicked(true);
  };

  return (
    <>
      {isMobile ? (
        <>
          <MobileContainer>
            <Box>
              <Box sx={{ paddingBottom: "8px" }}>
                <TextLight sx={{ fontStyle: "italic" }}>
                  {hasInvalidVendorCredentials(customerStores, store) ? (
                    <InvalidVendor vendor={store} />
                  ) : (
                    transformVendorName(store)
                  )}
                </TextLight>
                <TextLight sx={{ fontStyle: "italic" }}>
                  Item #: {storeProductId}
                </TextLight>
              </Box>
              <Box sx={{ padding: "8px 0" }}>
                <Text sx={{ paddingBottom: "8px", fontSize: "1.25rem" }}>
                  {name}
                </Text>
                <TextLight>{rawSize}</TextLight>
              </Box>
            </Box>
            {hasInvalidVendorCredentials(customerStores, store) ? (
              <AddItemButton
                clicked={clicked}
                addItem={addItem}
                disabled={true}
              />
            ) : (
              <AddItemButton clicked={clicked} addItem={addItem} />
            )}
          </MobileContainer>
          <Divider sx={{ borderColor: "#CACDCA" }} />
        </>
      ) : (
        <>
          <DesktopContainer>
            <Text>{storeProductId}</Text>
            <Text>{name}</Text>
            <Text sx={{ textAlign: "center" }}>{rawSize}</Text>
            {hasInvalidVendorCredentials(customerStores, store) ? (
              <InvalidVendor vendor={store} justifyContent="center" />
            ) : (
              <Text sx={{ textAlign: "center" }}>
                {transformVendorName(store)}
              </Text>
            )}
            {hasInvalidVendorCredentials(customerStores, store) ? (
              <AddItemButton
                clicked={clicked}
                addItem={addItem}
                disabled={true}
              />
            ) : (
              <AddItemButton clicked={clicked} addItem={addItem} />
            )}
          </DesktopContainer>
          <Divider sx={{ borderColor: "#CACDCA" }} />
        </>
      )}
    </>
  );
}

function AddItemButton({ clicked, addItem, disabled = false }) {
  return (
    <>
      {clicked ? (
        <Text sx={{ textAlign: "center", padding: "8px 0" }}>Added!</Text>
      ) : (
        <StyledButton
          disabled={disabled}
          onClick={addItem}
          sx={{
            height: "8px",
            padding: "16px 8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          Add
        </StyledButton>
      )}
    </>
  );
}
