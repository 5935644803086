import { Box } from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery'
import { Text } from "../../components/Elements";
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";
import AddButton from "./AddButton";
import RemoveButton from "./RemoveButton";

const Wrapper = styled("div")({
  maxWidth: "1100px",
  marginRight: "auto",
  marginLeft: "auto",
});

export default function ReadyToSetup() {
  const isMobile = useMediaQuery("(max-width:1100px)");

  return (
    <Wrapper>
      {isMobile ? (
        <MobileHeader
          title="Order Guide"
          firstButton={<AddButton />}
          secondButton={<RemoveButton enabled={false} />}
        />
      ) : (
        <DesktopHeader
          title="Order Guide"
          firstButton={<AddButton />}
          secondButton={<RemoveButton enabled={false} />}
          buttonSpacing="16px"
        />
      )}
      <Text sx={{ fontSize: "var(--size-500)", textAlign: "center" }}>
        Your order guide is ready to set up! Click the
        <Box sx={{ display: "inline", padding: "0 8px" }}>
          <AddButton display="inline" />
        </Box>
        button to begin
      </Text>
      <Text
        sx={{
          paddingTop: "32px",
          fontSize: "var(--size-500)",
          textAlign: "center",
        }}
      >
        Need help?{" "}
        <a href="https://www.foodmaven.com/order-guide" target="_blank">
          check out our guide
        </a>{" "}
        on how to set up and use your order guide.
      </Text>
    </Wrapper>
  );
}
