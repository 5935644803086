import { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { request, gql } from "graphql-request";
import Box from "@mui/material/Box";
import LoadingSpinner from "../components/LoadingSpinner";

function CustomerAPI({ setCustomerId, email, jwtToken }) {
  const [newCustomer, setNewCustomer] = useState(false);

  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const { status, data, error, isFetching } = useCustomer(
    endpoint,
    email,
    jwtToken,
    newCustomer
  );

  const {
    data: mutateData,
    error: mutateError,
    mutateAsync,
  } = useInsertCustomer(endpoint, email, jwtToken);

  useEffect(() => {
    if (mutateData) {
      if (mutateData[0]) {
        setCustomerId(mutateData[0].id);
      }
    }
  }, [mutateData]);

  useEffect(() => {
    if (data && data[0] && data[0].id) {
      setCustomerId(data[0].id);
    } else {
      if (status === "success") {
        setNewCustomer(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (newCustomer) {
      mutateAsync(email);
    }
  }, [newCustomer]);

  return (
    <>
      {status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}
export default CustomerAPI;

function useInsertCustomer(endpoint, email, jwtToken) {
  let variables = { email: email };
  const requestHeaders = {
    authorization: "Bearer " + jwtToken,
  };

  return useMutation(email, async () => {
    const data = await request(
      endpoint,
      gql`
        mutation InsertCustomer($email: String!) {
          insert_customer(objects: { email: $email }) {
            returning {
              id
            }
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data.insert_customer.returning;
  });
}

function useCustomer(endpoint, email, jwtToken, newCustomer) {
  let variables = { email: email };
  const requestHeaders = {
    authorization: "Bearer " + jwtToken,
  };

  return useQuery(["customer", email], async () => {
    const data = await request(
      endpoint,
      gql`
        query getId($email: String!) {
          customer(where: { email: { _eq: $email } }) {
            id
            email
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data.customer;
  });
}
