import { GraphQLClient } from "graphql-request";

const endpoint = process.env.REACT_APP_PRODUCT_API_ENDPOINT;

export default async function getPurchaseHistory(jwt, customerId) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + jwt,
    },
  });

  const variables = {
    cid: customerId,
  };

  const query = `
    query getPurchaseHistory($cid: String!) {
      purchase_history(where: {cid: {_eq: $cid}, _and: {_not: {order_guide: {cid: {_eq: $cid}}}}}) {
        id
        cid
        name
        rawPrice
        rawSize
        store
        storeProductId
      }
    }
  `;

  try {
    const data = await client.request(query, variables);
    return data.purchase_history;
  } catch (error) {
    console.log(error);
  }
}
