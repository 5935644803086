import { Link } from "@mui/material";
import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import { transformVendorName } from "../../helpers/data.helpers";
import { pricePerLb, savingsPerLb } from "../../helpers/math.helpers";

const Wrapper = styled("div")({
  paddingLeft: "32px",
  paddingRight: "32px",
});

const Container = styled("div")(({ theme }) => ({
  paddingTop: "8px",
  paddingBottom: "16px",
  borderTop: "1px solid",
  borderColor: theme.palette.grey.light,
}));

const DataContainer = styled("div")({
  padding: "8px 0",
});

const Data = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

const LinkText = styled((props) => <Text {...props} />)({
  color: "#3E92CC",
  paddingBottom: "8px",
});

const Savings = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.green.main,
  fontStyle: "bold",
}));

export default function MobileMatch({ match }) {
  if (!match) {
    return null;
  } else {
    return (
      <Wrapper>
        <Container>
          <Data sx={{ fontStyle: "italic" }}>
            {transformVendorName(match.store)}
          </Data>
          <DataContainer>
            <Link href={match.url} target="_blank">
              <LinkText>{match.name}</LinkText>
            </Link>
            <Data>{match.rawSize}</Data>
            <Data>
              {match.rawPrice}
              {pricePerLb(match) ? (
                <span>&nbsp; (${pricePerLb(match)}/LB)</span>
              ) : null}
            </Data>
            <Savings>
              {Math.round(match.savingsPct * 10) / 10}% savings
              {savingsPerLb(match) ? (
                <span>&nbsp; (${savingsPerLb(match)}/LB)</span>
              ) : null}
            </Savings>
          </DataContainer>
        </Container>
      </Wrapper>
    );
  }
}
