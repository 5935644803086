import { styled } from "@mui/system";
import { H1 } from "../../components/Elements";

const Container = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr",
  alignItems: "center",
});

const Justify = styled("div")({
  justifySelf: "end",
});

const FlexContainer = styled("div")({
  display: "flex",
});

const PadButton = styled("div")((props) => ({
  paddingRight: props.paddingRight
}));

export default function DesktopHeader({
  title,
  firstButton,
  secondButton,
  buttonSpacing = "none",
  paddingBottom = "48px",
}) {
  return (
    <Container sx={{ paddingBottom: paddingBottom }}>
      <div></div>
      <H1>{title}</H1>
      <Justify>
        <FlexContainer>
          <PadButton paddingRight={buttonSpacing}>
            {firstButton}
          </PadButton>
          {secondButton}
        </FlexContainer>
      </Justify>
    </Container>
  );
}
