import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import validCredentialsCheck from "../../api/ValidCredentialsCheck";
import getPurchaseHistory from "../../api/PurchaseHistoryAPI";
import NoCredentials from "./NoCredentials";
import NoValidCredentials from "./NoValidCredentials";
import NoPurchaseHistory from "./NoPurchaseHistory";
import OrderGuideDisplay from "./OrderGuideDisplay";

export default function OrderGuide({ customerId }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const { setIsSelected } = useOutletContext();
  const [state, setState] = useState({
    status: "pending",
    customerStores: [],
    purchaseHistory: [],
  });

  useEffect(() => {
    document.title = "Order Guide";
  }, []);

  useEffect(() => {
    if (customerId && user) {
      const jwt = user.signInUserSession.idToken.jwtToken;

      Promise.all([
        validCredentialsCheck(jwt, customerId),
        getPurchaseHistory(jwt, customerId),
      ]).then((data) => {
        setState({
          status: "resolved",
          customerStores: data[0],
          purchaseHistory: data[1],
        });
      });
    }
  }, [customerId]);

  const updateSelected = () => {
    setIsSelected({ type: "credentials" });
  };

  const hasValidCredentials = state.customerStores
    .map((store) => store.status)
    .includes("VALID");

  if (state.status === "pending") {
    return null;
  } else if (state.customerStores.length === 0) {
    return <NoCredentials updateSelected={updateSelected} />;
  } else if (!hasValidCredentials) {
    return <NoValidCredentials updateSelected={updateSelected} />;
  } else if (state.purchaseHistory.length === 0) {
    return <NoPurchaseHistory />;
  } else {
    return (
      <OrderGuideDisplay
        customerId={customerId}
        customerStores={state.customerStores}
      />
    );
  }
}
