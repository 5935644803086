import { useEffect } from "react";
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { H1 } from "../Elements";
import ChromeIcon from "../Icons/ChromeIcon";
import FirefoxIcon from "../Icons/FirefoxIcon";

const DownloadButton = styled("button")({
  marginTop: "32px",
  border: "3px solid #656964",
  borderRadius: "30px",
  padding: "8px 32px",
  color: "#656964",
  backgroundColor: "#FFFFFA",
  fontSize: "var(--size-600)",
  fontStyle: "italic",
  fontWeight: 700,
  filter: "drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25))",
  cursor: "pointer",
});

export default function Download() {
  const isMobile = useMediaQuery("(pointer: coarse)");

  useEffect(() => {
    document.title = "Download";
  }, []);

  const openChromeWebstore = (event) => {
    event.preventDefault();
    window.open(
      "https://chrome.google.com/webstore/detail/mymaven/hmadkphjnafnfhaaglofndefdepoaioj?hl=en",
      "_blank"
    );
  };

  const openFirefoxWebstore = (event) => {
    event.preventDefault();
    window.open(
      "https://addons.mozilla.org/en-US/firefox/addon/foodmaven",
      "_blank"
    );
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <H1>Download</H1>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "16px" }}>
        {isMobile ? (
          <p>Login on desktop to download your Smart Shopping Assistant</p>
        ) : (
          <>
            <DownloadButton onClick={openChromeWebstore}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ marginRight: "16px" }}>
                  <ChromeIcon width="40" fill="#656964" />
                </Box>
                Add to Chrome - It's Free
              </Box>
            </DownloadButton>
            <DownloadButton onClick={openFirefoxWebstore}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ marginRight: "16px" }}>
                  <FirefoxIcon width="40" fill="#656964" />
                </Box>
                Add to Firefox - It's Free
              </Box>
            </DownloadButton>
          </>
        )}
      </Box>
    </Box>
  );
}
