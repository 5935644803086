function ChromeIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M30.0003 2.68725C25.3756 0.017408 19.9859 -0.692549 14.8262 0.689868C9.66403 2.07228 5.3518 5.37959 2.68196 10.0068C-2.83271 19.5562 0.452094 31.813 10.0015 37.3252C13.1413 39.1376 16.5761 40 19.9659 40C26.883 40 33.6201 36.4127 37.3199 30.0056C42.832 20.4562 39.5472 8.19942 30.0003 2.68725ZM35.155 28.7557C30.3278 37.1102 19.6034 39.985 11.2514 35.1603C2.89445 30.3356 0.0221198 19.6137 4.84683 11.2567C7.18418 7.20948 10.9565 4.31465 15.4737 3.10472C16.9811 2.70225 18.511 2.50226 20.0284 2.50226C23.0632 2.50226 26.0555 3.29471 28.7504 4.85212C37.1024 9.67683 39.9772 20.3987 35.155 28.7557Z"
      />
      <path
        fill={fill}
        d="M28.4504 17.7413C27.8479 15.484 26.4005 13.5966 24.3756 12.4291C20.1959 10.0143 14.8387 11.4517 12.4238 15.6315C10.0115 19.8087 11.4489 25.1709 15.6261 27.5832C17.0011 28.3782 18.501 28.7557 19.9859 28.7557C23.0107 28.7557 25.958 27.1858 27.5754 24.3809C28.7453 22.356 29.0553 19.9987 28.4504 17.7413ZM25.413 23.131C24.5781 24.5759 23.2307 25.6109 21.6158 26.0433C20.0009 26.4758 18.3185 26.2508 16.8761 25.4209C13.8912 23.696 12.8663 19.8662 14.5887 16.8814C15.4236 15.4365 16.7711 14.4015 18.3835 13.9691C19.9959 13.5366 21.6808 13.7591 23.1257 14.594C24.5706 15.429 25.603 16.7764 26.0355 18.3888C26.468 20.0012 26.2455 21.6861 25.413 23.131Z"
      />
      <path
        fill={fill}
        d="M25.4132 23.13L16.8215 38.0154L18.9867 39.2651L27.5783 24.3797L25.4132 23.13Z"
      />
      <path
        fill={fill}
        d="M5.99575 8.25009L3.83081 9.50031L12.4236 24.3801L14.5886 23.1299L5.99575 8.25009Z"
      />
      <path fill={fill} d="M37.183 11.2563H20.0012V13.7562H37.183V11.2563Z" />
    </svg>
  );
}

export default ChromeIcon;
