function ProfileIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fill}
          d="m166 196c0 49.626 40.374 90 90 90s90-40.374 90-90-40.374-90-90-90-90 40.374-90 90zm160 0c0 38.598-31.402 70-70 70s-70-31.402-70-70 31.402-70 70-70 70 31.402 70 70z"
        />
        <circle fill={fill} cx="440" cy="92" r="10" />
        <path
          fill={fill}
          d="m256 512c141.904 0 256-114.927 256-256 0-46.969-12.806-92.884-37.031-132.782-2.866-4.72-9.015-6.223-13.738-3.358-4.721 2.867-6.224 9.017-3.357 13.738 22.325 36.769 34.126 79.095 34.126 122.402 0 68.428-28.771 131.896-79.476 176.683-15.436-72.809-79.996-126.683-156.524-126.683s-141.095 53.88-156.515 126.692c-50.711-44.792-79.485-108.263-79.485-176.692 0-130.131 105.869-236 236-236 52.989 0 103.031 17.115 144.716 49.496 4.361 3.388 10.644 2.598 14.032-1.763s2.599-10.644-1.763-14.032c-45.226-35.132-99.511-53.701-156.985-53.701-68.436 0-132.738 26.616-181.063 74.944-48.324 48.326-74.937 112.626-74.937 181.056 0 141.429 114.459 256 256 256zm0-186c70.697 0 129.381 51.64 138.722 121.007-40.455 29.453-88.293 44.993-138.722 44.993-50.424 0-98.256-15.542-138.711-44.996 9.328-69.366 68.006-121.004 138.711-121.004z"
        />
      </g>
    </svg>
  );
}

export default ProfileIcon;
