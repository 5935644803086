import { useState } from "react";
import { styled } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import { Divider } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import DrawerList from "./DrawerList";
import ChevronIcon from "./Icons/ChevronIcon";
import HamburgerMenuIcon from "./Icons/HamburgerMenuIcon";

const drawerWidth = "18rem";
const iconWidth = "40px";
const sidebarPadding = "24px";

const BrandLogo = styled("img")({
  paddingTop: "2rem",
  paddingBottom: "2rem",
});

const ChevronContainer = styled("div")({
  position: "fixed",
  top: "84px",
  left: "274px",
  zIndex: 2400,
  cursor: "pointer",
});

const HamburgerContainer = styled("div")({
  marginTop: "2rem",
  marginBottom: "2rem",
  cursor: "pointer",
});

const DrawerWrapper = styled("div")({
  height: "100%",
  marginRight: "auto",
  marginLeft: "auto",
  paddingRight: sidebarPadding,
  paddingLeft: sidebarPadding,
});

const DrawerContainer = styled("nav")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
});

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${sidebarPadding} * 3.6)`,
});

export default function SideDrawer({ isSelected, setIsSelected }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const StyledDivider = styled(Divider)({
    borderBottomWidth: 2,
    borderColor: theme.palette.grey.light,
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  return (
    <>
      {open ? (
        <ChevronContainer onClick={toggleDrawer}>
          <ChevronIcon ariaLabel="Collapse drawer" width="30px" height="30px" />
        </ChevronContainer>
      ) : (
        <div>{null}</div>
      )}
      <Drawer variant="permanent" open={open}>
        <DrawerWrapper>
          <DrawerContainer>
            {open ? (
              <>
                <BrandLogo src="./foodmavenLogo.png" />
                <StyledDivider />
              </>
            ) : (
              <>
                <HamburgerContainer onClick={toggleDrawer}>
                  <HamburgerMenuIcon
                    width={iconWidth}
                    height="36px"
                    fill={theme.palette.grey.main}
                  />
                </HamburgerContainer>
                <StyledDivider sx={{ width: iconWidth }} />
              </>
            )}
            <DrawerList
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              closeTopNavModal={() => null}
            />
          </DrawerContainer>
        </DrawerWrapper>
      </Drawer>
    </>
  );
}
