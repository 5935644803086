import { useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { styled } from "@mui/system";
import { Box, Divider, Typography } from "@mui/material";
import { StyledButton } from "../../components/Elements";
import { removeOrderGuideItem } from "../../api/OrderGuideAPI";
import { transformVendorName } from "../../helpers/data.helpers";

const MobileContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "auto 1fr",
  gap: "80px",
  alignItems: "center",
  justifyItems: "end",
  padding: "16px 0",
});

const DesktopContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 3fr 1fr 1fr 1fr",
  gap: "16px",
  alignItems: "center",
  padding: "16px 0",
});

const Text = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

const TextLight = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

export default function OrderGuideItem({
  id,
  name,
  rawSize,
  store,
  storeProductId,
  isMobile = false,
}) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [clicked, setClicked] = useState(false);

  const removeItem = () => {
    const jwt = user.signInUserSession.idToken.jwtToken;

    removeOrderGuideItem(jwt, id);
    setClicked(true);
  };

  return (
    <>
      {isMobile ? (
        <>
          <MobileContainer>
            <Box>
              <Box sx={{ paddingBottom: "8px" }}>
                <TextLight sx={{ fontStyle: "italic" }}>
                  {transformVendorName(store)}
                </TextLight>
                <TextLight sx={{ fontStyle: "italic" }}>
                  Item #: {storeProductId}
                </TextLight>
              </Box>
              <Box sx={{ padding: "8px 0" }}>
                <Text sx={{ paddingBottom: "8px", fontSize: "1.25rem" }}>
                  {name}
                </Text>
                <TextLight>{rawSize}</TextLight>
              </Box>
            </Box>
            <RemoveItemButton clicked={clicked} removeItem={removeItem} />
          </MobileContainer>
          <Divider sx={{ borderColor: "#CACDCA" }} />
        </>
      ) : (
        <>
          <DesktopContainer>
            <Text>{storeProductId}</Text>
            <Text>{name}</Text>
            <Text sx={{ textAlign: "center" }}>{rawSize}</Text>
            <Text sx={{ textAlign: "center" }}>
              {transformVendorName(store)}
            </Text>
            <RemoveItemButton clicked={clicked} removeItem={removeItem} />
          </DesktopContainer>
          <Divider sx={{ borderColor: "#CACDCA" }} />
        </>
      )}
    </>
  );
}

function RemoveItemButton({ clicked, removeItem }) {
  return (
    <>
      {clicked ? (
        <Text sx={{ textAlign: "center", padding: "8px 0" }}>Removed!</Text>
      ) : (
        <StyledButton
          onClick={removeItem}
          sx={{
            backgroundColor: "#E57505",
            height: "8px",
            padding: "16px 8px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "&:hover": {
              backgroundColor: "rgba(229, 117, 5, 0.80)",
              boxShadow: "none",
            },
          }}
        >
          Remove
        </StyledButton>
      )}
    </>
  );
}
