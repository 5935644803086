import { styled } from "@mui/system";
import MobileHeader from "./MobileHeader";
import AddButton from "./AddButton";
import RemoveButton from "./RemoveButton";
import MatchAccordion from "./MatchAccordion";
import MobileMatch from "./MobileMatch";
import Sort from "./Sort";
import Filter from "./Filter";

const Container = styled("div")({
  display: "flex",
  paddingBottom: "32px",
});

const PadButton = styled("div")({
  paddingLeft: "16px",
});

export default function MobileView({
  items,
  memoizedItems,
  customerStores,
  sortState,
  setSortState,
  filterState,
  setFilterState,
}) {
  return (
    <>
      <MobileHeader
        title="Order Guide"
        firstButton={<AddButton />}
        secondButton={<RemoveButton />}
        paddingBottom="16px"
      />
      <Container>
        <Sort
          items={items}
          memoizedItems={memoizedItems}
          sortState={sortState}
          setSortState={setSortState}
        />
        <PadButton>
          <Filter filterState={filterState} setFilterState={setFilterState} />
        </PadButton>
      </Container>
      {sortState.items.map((item) => (
        <MatchAccordion
          key={item.id}
          item={item}
          customerStores={customerStores}
        >
          {Object.keys(item.matches).map((vendor, index) => (
            filterState.vendors[vendor].display ? (
              <MobileMatch key={index} match={item.matches[vendor]} />
            ) : null
          ))}
        </MatchAccordion>
      ))}
    </>
  );
}
