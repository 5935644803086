import { Link } from "@mui/material";
import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import {
  transformVendorName,
  hasInvalidVendorCredentials,
} from "../../helpers/data.helpers";
import { pricePerLb } from "../../helpers/math.helpers";
import InvalidItem from "./InvalidItem";
import InvalidVendor from "./InvalidVendor";

const DataContainer = styled("div")((props) => ({
  display: "flex",
  alignItems: props.alignItems ? props.alignItems : "none",
}));

const Data = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

const LinkText = styled((props) => <Text {...props} />)({
  color: "#3E92CC",
});

export default function GridItem({ item, customerStores }) {
  if (item.url) {
    return <HasComps item={item} customerStores={customerStores} />;
  } else {
    return <NoComps item={item} />;
  }
}

function HasComps({ item, customerStores }) {
  return (
    <>
      <Link href={item.url} target="_blank">
        <LinkText>{item.name}</LinkText>
      </Link>
      <DataContainer>
        <Text>Pack/Size:&nbsp;</Text>
        <Data>{item.rawSize}</Data>
      </DataContainer>
      <DataContainer>
        <Text>Price:&nbsp;</Text>
        <Data>{item.rawPrice}</Data>
        {pricePerLb(item) ? <Data>&nbsp; (${pricePerLb(item)}/LB)</Data> : null}
      </DataContainer>
      <DataContainer>
        {hasInvalidVendorCredentials(customerStores, item.store) ? (
          <InvalidVendor vendor={item.store} />
        ) : (
          <>
            <Text>Vendor:&nbsp;</Text>
            <Data>{transformVendorName(item.store)}</Data>
          </>
        )}
      </DataContainer>
      <DataContainer>
        <Text>Item #:&nbsp;</Text>
        <Data>{item.storeProductId || item.productId}</Data>
      </DataContainer>
    </>
  );
}

function NoComps({ item }) {
  return (
    <>
      <Data>{item.name}</Data>
      <Text>Pack/Size: </Text>
      <Text>Price: </Text>
      <DataContainer>
        <Text>Vendor:&nbsp;</Text>
        <Data>{transformVendorName(item.store)}</Data>
      </DataContainer>
      <DataContainer alignItems="baseline">
        <Text>Item #:&nbsp;</Text>
        <InvalidItem itemNumber={item.storeProductId || item.productId} />
      </DataContainer>
    </>
  );
}
