import { styled } from "@mui/system";
import { Box, List } from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import DrawerItem from "./DrawerItem";
import OrderGuideIcon from "./Icons/OrderGuideIcon";
import CredentialsIcon from "./Icons/CredentialsIcon";
import DownloadIcon from "./Icons/DownloadIcon";
import ProfileIcon from "./Icons/ProfileIcon";
import LogoutIcon from "./Icons/LogoutIcon";

const StyledList = styled(List)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  paddingTop: "1.5rem",
  paddingBottom: 0,
});

const iconWidth = "40px";
const iconHeight = "40px";

export default function DrawerList({
  isSelected,
  setIsSelected,
  closeTopNavModal,
}) {
  const { signOut } = useAuthenticator();

  const handleLogout = () => {
    signOut();
  };

  return (
    <StyledList>
      <Box>
        <DrawerItem
          to="/"
          text="Order Guide"
          isSelected={isSelected.orderGuide}
          setIsSelected={() => setIsSelected({ type: "orderGuide" })}
          closeModal={closeTopNavModal}
        >
          <OrderGuideIcon
            ariaLabel="View your credentials page"
            width={iconWidth}
            height={iconHeight}
          />
        </DrawerItem>
        <DrawerItem
          to="/credentials"
          text="Credentials"
          isSelected={isSelected.credentials}
          setIsSelected={() => setIsSelected({ type: "credentials" })}
          closeModal={closeTopNavModal}
        >
          <CredentialsIcon
            ariaLabel="View your credentials page"
            width={iconWidth}
            height={iconHeight}
          />
        </DrawerItem>
        <DrawerItem
          to="/download"
          text="Download"
          isSelected={isSelected.download}
          setIsSelected={() => setIsSelected({ type: "download" })}
          closeModal={closeTopNavModal}
        >
          <DownloadIcon
            ariaLabel="Download the browser extension"
            width={iconWidth}
            height={iconHeight}
          />
        </DrawerItem>
        <DrawerItem
          to="/profile"
          text="Profile"
          isSelected={isSelected.profile}
          setIsSelected={() => setIsSelected({ type: "profile" })}
          closeModal={closeTopNavModal}
        >
          <ProfileIcon
            ariaLabel="View your profile page"
            width={iconWidth}
            height={iconHeight}
          />
        </DrawerItem>
      </Box>
      <Box onClick={handleLogout}>
        <DrawerItem to="/" text="Log Out" isLogout={true}>
          <LogoutIcon
            ariaLabel="Log out"
            width={iconWidth}
            height={iconHeight}
          />
        </DrawerItem>
      </Box>
    </StyledList>
  );
}
