import { useState, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Text } from "../../components/Elements";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { transformVendorName } from "../../helpers/data.helpers";

const FilterButton = styled((props) => (
  <button id="filter-button" aria-haspopup="true" {...props}></button>
))({
  display: "flex",
  border: "1.5px solid #878C87",
  borderRadius: "16px",
  cursor: "pointer",
  padding: "4px 8px",
  color: "#878C87",
  background: "#FFFFFF",
});

const FilterTextContainer = styled("span")({
  paddingRight: "8px",
});

const SelectButton = styled((props) => <button {...props}></button>)({
  display: "flex",
  border: "1.5px solid #878C87",
  borderRadius: "16px",
  cursor: "pointer",
  padding: "4px 8px",
});

const StyledMenuItem = styled((props) => <MenuItem {...props} />)({
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#878C87",
  },
});

const MenuItemContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alginItems: "baseline",
  width: "100%",
});

export default function Vendors({ filterState, setFilterState }) {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleVendor = (event) => {
    handleClose();

    const vendor = event.target.innerText;
    const newState = { ...filterState.vendors };

    // Toggle display boolean
    newState[vendor.toLowerCase()].display =
      !newState[vendor.toLowerCase()].display;

    // Toggle isSelectedOnMobile boolean
    newState[vendor.toLowerCase()].isSelectedOnMobile =
      !newState[vendor.toLowerCase()].isSelectedOnMobile;

    setFilterState({
      ...filterState,
      vendors: newState
    });
  };

  return (
    <>
      <FilterButton
        onClick={handleClick}
        aria-controls={open ? "filter-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
      >
        <FilterTextContainer>Vendors</FilterTextContainer>
        <KeyboardArrowDownIcon />
      </FilterButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sort-menu-button",
        }}
      >
        {Object.keys(filterState.vendors).map((vendor, index) => (
          <StyledMenuItem key={index} onClick={toggleVendor}>
            <MenuItemContainer>
              {transformVendorName(vendor)}
              {filterState.vendors[vendor].display ? (
                <CheckIcon sx={{ paddingLeft: "8px" }} />
              ) : null}
            </MenuItemContainer>
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
}
