import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Divider, Typography } from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getOrderGuideItems } from "../../api/OrderGuideAPI";
import OrderGuideItem from "./OrderGuideItem";
import DesktopHeader from "./DesktopHeader";
import ReturnButton from "./ReturnButton";

const Wrapper = styled("div")({
  maxWidth: "1100px",
  marginRight: "auto",
  marginLeft: "auto",
});

const TableHeaders = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr 3fr 1fr 1fr 1fr",
  gap: "16px",
});

const TableHeader = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontSize: "1.5rem",
    color: theme.palette.grey.dark,
  })
);

export default function RemoveFromOrderGuide({ customerId }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const isMobile = useMediaQuery("(max-width:800px)");
  const [orderGuideItems, setOrderGuideItems] = useState([]);

  useEffect(() => {
    if (customerId) {
      const jwt = user.signInUserSession.idToken.jwtToken;

      getOrderGuideItems(jwt, customerId).then((data) => {
        setOrderGuideItems(data);
      });
    }
  }, [customerId]);

  return (
    <Wrapper>
      <DesktopHeader
        title="Remove Items"
        firstButton={<ReturnButton />}
        secondButton={null}
      />
      {isMobile ? null : (
        <TableHeaders>
          <TableHeader>Item #</TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader sx={{ textAlign: "center" }}>Size</TableHeader>
          <TableHeader sx={{ textAlign: "center" }}>Vendor</TableHeader>
        </TableHeaders>
      )}
      <Divider sx={{ borderColor: "#CACDCA" }} />
      {orderGuideItems.map((item) => (
        <OrderGuideItem
          key={item.id}
          id={item.id}
          name={item.name}
          rawSize={item.rawSize}
          store={item.store}
          storeProductId={item.storeProductId}
          isMobile={isMobile}
        />
      ))}
    </Wrapper>
  );
}
