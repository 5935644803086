import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "@mui/system";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "./index.css";
import "./fonts/Lato/Lato-Regular.ttf";
import App from "./components/App";
import theme from "./theme";
import awsExports from "./aws-exports";

const container = document.getElementById("root");
const root = createRoot(container);

Amplify.configure(awsExports);

root.render(
  <Authenticator.Provider>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Authenticator.Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
