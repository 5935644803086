function DownloadIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fill}
          d="M454.121,91.163c-36.806-44.92-88.185-76.266-144.67-88.268c-2.458-0.523-5.02,0.092-6.973,1.675
			c-1.953,1.581-3.087,3.959-3.087,6.472v84.003c0,4.601,3.729,8.329,8.329,8.329s8.329-3.728,8.329-8.329V21.56
			c48.821,12.644,92.971,40.842,125.188,80.162c34.891,42.582,54.106,96.409,54.106,151.567c0,52.901-16.918,103.043-48.923,145.006
			c-2.79,3.658-2.086,8.884,1.571,11.675c1.508,1.151,3.284,1.706,5.045,1.706c2.509,0,4.99-1.129,6.629-3.278
			C493.903,363.506,512,309.87,512,253.288C512,194.292,491.444,136.716,454.121,91.163z"
        />
        <path
          fill={fill}
          d="M436.872,422.666c-3.249-3.256-8.523-3.26-11.778-0.01c-45.278,45.205-105.192,69.976-169.061,69.974
			c-1.146,0-2.302-0.007-3.452-0.023c-63.104-0.881-122.389-26.272-166.932-71.499c-44.54-45.223-69.041-104.89-68.991-168.013
			c0.04-50.356,15.54-98.517,44.825-139.278c2.684-3.736,1.831-8.94-1.905-11.624c-3.736-2.685-8.94-1.831-11.624,1.905
			C16.625,147.704,0.042,199.223,0,253.082c-0.053,67.53,26.15,131.353,73.781,179.715c47.638,48.368,111.055,75.524,178.568,76.465
			c1.233,0.017,2.461,0.026,3.691,0.026c68.305-0.001,132.397-26.495,180.821-74.844
			C440.118,431.195,440.122,425.921,436.872,422.666z"
        />
        <path
          fill={fill}
          d="M377.875,212.215c-3.28-5.707-9.168-9.114-15.752-9.114h-44.565c-0.833,0-1.509-0.676-1.509-1.509v-73.23
			c0-4.601-3.729-8.329-8.329-8.329s-8.329,3.728-8.329,8.329v73.23c0,10.017,8.149,18.167,18.167,18.167h44.565
			c0.58,0,1.021,0.255,1.309,0.757s0.288,1.011-0.004,1.511L257.307,404.178c-0.29,0.496-0.729,0.749-1.305,0.749
			c-0.575,0-1.014-0.252-1.304-0.749L148.575,222.027c-0.292-0.501-0.293-1.009-0.004-1.511c0.289-0.503,0.728-0.757,1.308-0.757
			h44.565c10.017,0,18.167-8.149,18.167-18.167V11.042c0-2.513-1.135-4.891-3.087-6.472c-1.952-1.583-4.515-2.2-6.973-1.675
			c-50.812,10.795-96.652,36.572-132.566,74.542c-3.161,3.342-3.014,8.613,0.328,11.775c3.343,3.161,8.614,3.013,11.775-0.329
			c31.194-32.979,70.385-56.118,113.866-67.33v180.038c0,0.833-0.676,1.509-1.509,1.509h-44.565c-6.583,0-12.471,3.407-15.752,9.114
			c-3.281,5.708-3.26,12.51,0.053,18.198l106.123,182.151c3.292,5.649,9.159,9.021,15.697,9.021c6.538,0,12.406-3.372,15.698-9.021
			l106.123-182.151C381.136,224.725,381.156,217.922,377.875,212.215z"
        />
      </g>
    </svg>
  );
}

export default DownloadIcon;
