import { GraphQLClient } from "graphql-request";

const endpoint = process.env.REACT_APP_PRODUCT_API_ENDPOINT;

async function getOrderGuideItems(jwt, customerId) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + jwt,
    },
  });

  const variables = {
    cid: customerId,
  };

  const query = `
    query getOrderGuideItems($cid: String!) {
      order_guide(where: {cid: {_eq: $cid}}) {
        id
        name
        rawPrice
        rawSize
        store
        storeProductId
      }
    }
  `;

  try {
    const data = await client.request(query, variables);
    return data.order_guide;
  } catch (error) {
    console.log(error);
  }
}

async function insertOrderGuideItem(
  jwt,
  customerId,
  purchaseHistoryId,
  name,
  rawPrice,
  rawSize,
  store,
  storeProductId,
  location = null,
  attributes = null
) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + jwt,
    },
  });

  const variables = {
    cid: customerId,
    purchaseHistoryId: purchaseHistoryId,
    name: name,
    rawPrice: rawPrice,
    rawSize: rawSize,
    store: store,
    storeProductId: storeProductId,
    location: location,
    attributes: attributes,
  };

  const mutation = `
    mutation insertOrderGuideItem(
      $cid: String!,
      $purchaseHistoryId: uuid!,
      $name: String!,
      $rawPrice: String!,
      $rawSize: String!,
      $store: String!,
      $storeProductId: String!
      $location: String
      $attributes: String
    ) {
      insert_order_guide(objects: {
        cid: $cid,
        purchaseHistoryId: $purchaseHistoryId,
        name: $name,
        rawPrice: $rawPrice,
        rawSize: $rawSize,
        store: $store,
        storeProductId: $storeProductId,
        location: $location,
        attributes: $attributes
      }) {
        affected_rows
        returning {
          id
        }
      }
    }
  `;

  try {
    const data = await client.request(mutation, variables);
    return data.order_guide;
  } catch (error) {
    console.log(error);
  }
}

async function removeOrderGuideItem(jwt, id) {
  const client = new GraphQLClient(endpoint, {
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + jwt,
    },
  });

  const variables = {
    id: id,
  };

  const mutation = `
    mutation removeOrderGuideItem($id: uuid!) {
      delete_order_guide(where: {id: {_eq: $id}}) {
        returning {
          id
        }
      }
    }
  `;

  try {
    const data = await client.request(mutation, variables);
    return data.order_guide;
  } catch (error) {
    console.log(error);
  }
}

export { getOrderGuideItems, insertOrderGuideItem, removeOrderGuideItem };
