function CloseNavIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M1.46487 30C1.17512 30 0.89185 29.9141 0.650908 29.7532C0.409965 29.5922 0.22217 29.3634 0.111277 29.0957C0.00038335 28.828 -0.0286259 28.5334 0.0279181 28.2493C0.084462 27.9651 0.224019 27.704 0.428936 27.4992L27.4993 0.428855C27.774 0.154108 28.1467 -0.000244141 28.5352 -0.000244141C28.9238 -0.000244141 29.2964 0.154108 29.5712 0.428855C29.8459 0.703603 30.0003 1.07624 30.0003 1.46479C30.0003 1.85334 29.8459 2.22598 29.5712 2.50073L2.50081 29.571C2.3649 29.7072 2.20341 29.8152 2.02563 29.8888C1.84786 29.9625 1.65729 30.0002 1.46487 30Z"
      />
      <path
        fill={fill}
        d="M28.5351 30C28.3427 30.0002 28.1521 29.9625 27.9743 29.8888C27.7966 29.8152 27.6351 29.7072 27.4992 29.571L0.428855 2.50073C0.154108 2.22598 -0.000244141 1.85334 -0.000244141 1.46479C-0.000244141 1.07624 0.154108 0.703603 0.428855 0.428855C0.703603 0.154108 1.07624 -0.000244141 1.46479 -0.000244141C1.85334 -0.000244141 2.22598 0.154108 2.50073 0.428855L29.571 27.4992C29.776 27.704 29.9155 27.9651 29.9721 28.2493C30.0286 28.5334 29.9996 28.828 29.8887 29.0957C29.7778 29.3634 29.59 29.5922 29.3491 29.7532C29.1081 29.9141 28.8249 30 28.5351 30V30Z"
      />
    </svg>
  );
}

export default CloseNavIcon;
