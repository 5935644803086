import { useState, useRef } from "react";
import { Controller } from "react-hook-form";
import { styled } from "@mui/system";
import { MenuItem, TextField } from "@mui/material";
import StoreLocationsAPI from "../../../api/StoreLocationsAPI";

const StyledTextField = styled((props) => (
  <TextField
    select
    variant="outlined"
    size="small"
    margin="dense"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiInputBase-input": {
    "&:focus": {
      color: theme.palette.grey.dark,
    },
  },
  "& label": {
    color: theme.palette.grey.main,
  },
  "& label.Mui-focused": {
    color: theme.palette.grey.dark,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.grey.dark,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.grey.dark,
    },
  },
}));

export default function StoreLocations({
  control,
  storeLocation,
  customerId,
  jwtToken,
}) {
  const [storeLocationsData, setStoreLocationsData] = useState(null);
  const nodeRef = useRef(null);

  return (
    <>
      {jwtToken && (
        <StoreLocationsAPI
          setStoreLocationsData={setStoreLocationsData}
          storeName="Restaurant Depot"
          jwtToken={jwtToken}
        />
      )}
      {storeLocationsData ? (
        <Controller
          name="storeLocation"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              id="storeLocation"
              label="Store"
              ref={nodeRef}
              onChange={(e) => field.onChange((storeLocation = e.target.value))}
              {...field}
            >
              {storeLocationsData.map((store) => (
                <MenuItem value={store.id} key={store.id} ref={nodeRef}>
                  {store.state} - {store.city}
                </MenuItem>
              ))}
            </StyledTextField>
          )}
        />
      ) : null}
    </>
  );
}
