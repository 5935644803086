import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomerAPI from "../../api/CustomerAPI";

import Auth from "../Auth";
import Home from "../Home";
import Profile from "../Profile";
import Download from "../Download";
import Credentials from "../Credentials";
import OrderGuide from "../OrderGuide";
import AddToOrderGuide from "../OrderGuide/AddToOrderGuide";
import RemoveFromOrderGuide from "../OrderGuide/RemoveFromOrderGuide";

export default function App() {
  const { user } = useAuthenticator();
  const [customerId, setCustomerId] = useState(null);

  return (
    <BrowserRouter>
      {user ? (
        <QueryClientProvider client={new QueryClient()}>
          <CustomerAPI
            setCustomerId={setCustomerId}
            email={user.attributes.email}
            jwtToken={user.signInUserSession.idToken.jwtToken}
          />
          <Routes>
            <Route element={<Home customerId={customerId} />}>
              <Route
                path="/order-guide"
                element={<OrderGuide customerId={customerId} />}
              />
              <Route
                path="/add-to-order-guide"
                element={<AddToOrderGuide customerId={customerId} />}
              />
              <Route
                path="/remove-from-order-guide"
                element={<RemoveFromOrderGuide customerId={customerId} />}
              />
              <Route
                path="/credentials"
                element={
                  <Credentials
                    customerId={customerId}
                    jwtToken={user.signInUserSession.idToken.jwtToken}
                  />
                }
              />
              <Route path="/download" element={<Download />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="*" element={<Navigate to="/order-guide" />} />
            </Route>
          </Routes>
        </QueryClientProvider>
      ) : (
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/signin" element={<Auth />} />
          <Route path="/signup" element={<Auth initialState="signUp" />} />
          <Route path="*" element={<Navigate to="/signin" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}
