export default function ReturnToOrderGuideIcon({
  ariaLabel = "",
  width = "",
  height = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 44"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g filter="url(#filter0_d_415_1460)">
        <rect x="2" width="40" height="40" rx="5" fill="white" />
        <rect
          x="2.75"
          y="0.75"
          width="38.5"
          height="38.5"
          rx="4.25"
          stroke="#878C87"
          strokeWidth="1.5"
        />
      </g>
      <path
        fill="#878C87"
        d="M33.7517 17.939C33.9968 17.939 34.1953 17.7404 34.1953 17.4954V7.84977C34.1953 6.27852 32.917 5.00018 31.3457 5.00018C30.3317 5.00018 13.8533 5 12.9624 5C11.2213 5 9.80469 6.4165 9.80469 8.15768V33.7765C9.80469 34.4511 10.3535 35 11.0282 35H28.1302C28.8048 35 29.3537 34.4512 29.3537 33.7765V25.7928H33.0909C33.6999 25.7928 34.1954 25.2973 34.1954 24.6884V19.2697C34.1954 19.0247 33.9968 18.8261 33.7518 18.8261C33.5068 18.8261 33.3082 19.0247 33.3082 19.2697V24.6884C33.3082 24.8081 33.2107 24.9056 33.0909 24.9056H29.3537C29.3536 24.1912 29.3514 9.15072 29.3513 7.84982C29.3513 6.90406 30.0239 6.1124 30.9159 5.92795C32.1799 5.66738 33.3082 6.621 33.3082 7.84982V17.4955C33.3081 17.7404 33.5067 17.939 33.7517 17.939ZM28.6861 6.74703C28.3623 7.51578 28.465 7.14248 28.464 25.3492C28.464 25.3591 28.4664 33.7663 28.4664 33.7765C28.4664 33.962 28.3155 34.1128 28.1301 34.1128H11.0282C10.8427 34.1128 10.6919 33.962 10.6919 33.7765V8.15768C10.6919 6.9057 11.7104 5.88717 12.9624 5.88717H29.2484C29.0201 6.12734 28.8222 6.42412 28.6861 6.74703Z"
      />
      <path
        fill="#878C87"
        d="M14.2247 8.67285C13.3922 8.67285 12.7148 9.35014 12.7148 10.1826C12.7148 11.0151 13.3922 11.6924 14.2247 11.6924C15.0572 11.6924 15.7345 11.0151 15.7345 10.1826C15.7345 9.35014 15.0572 8.67285 14.2247 8.67285ZM14.2247 10.8053C13.8814 10.8053 13.6021 10.5259 13.6021 10.1826C13.6021 9.83934 13.8813 9.56002 14.2247 9.56002C14.568 9.56002 14.8473 9.83934 14.8473 10.1826C14.8473 10.5259 14.5679 10.8053 14.2247 10.8053Z"
      />
      <path
        fill="#878C87"
        d="M18.5418 9.73901H17.6897C17.4447 9.73901 17.2461 9.93759 17.2461 10.1826C17.2461 10.4277 17.4447 10.6262 17.6897 10.6262H18.5418C18.7868 10.6262 18.9854 10.4277 18.9854 10.1826C18.9854 9.93753 18.7868 9.73901 18.5418 9.73901Z"
      />
      <path
        fill="#878C87"
        d="M26.3495 9.73901H20.3167C20.0716 9.73901 19.873 9.93759 19.873 10.1826C19.873 10.4277 20.0716 10.6262 20.3166 10.6262H26.3494C26.5944 10.6262 26.793 10.4277 26.793 10.1826C26.793 9.93753 26.5945 9.73901 26.3495 9.73901Z"
      />
      <path
        fill="#878C87"
        d="M14.2246 13.6133C13.3921 13.6133 12.7148 14.2906 12.7148 15.1231C12.7148 15.9556 13.3921 16.6329 14.2246 16.6329C15.0571 16.6329 15.7344 15.9556 15.7344 15.1231C15.7344 14.2906 15.0571 13.6133 14.2246 13.6133ZM14.2246 15.7457C13.8813 15.7457 13.602 15.4664 13.602 15.1231C13.602 14.7798 13.8813 14.5005 14.2246 14.5005C14.5679 14.5005 14.8472 14.7798 14.8472 15.1231C14.8472 15.4664 14.5679 15.7457 14.2246 15.7457Z"
      />
      <path
        fill="#878C87"
        d="M26.3489 14.6794H17.6897C17.4447 14.6794 17.2461 14.878 17.2461 15.123C17.2461 15.368 17.4447 15.5666 17.6897 15.5666H26.3489C26.594 15.5666 26.7925 15.368 26.7925 15.123C26.7925 14.878 26.594 14.6794 26.3489 14.6794Z"
      />
      <path
        fill="#878C87"
        d="M14.2246 18.5537C13.3921 18.5537 12.7148 19.231 12.7148 20.0635C12.7148 20.896 13.3921 21.5733 14.2246 21.5733C15.0571 21.5733 15.7344 20.896 15.7344 20.0635C15.7344 19.231 15.0571 18.5537 14.2246 18.5537ZM14.2246 20.6862C13.8813 20.6862 13.602 20.4069 13.602 20.0636C13.602 19.7203 13.8813 19.441 14.2246 19.441C14.5679 19.441 14.8472 19.7203 14.8472 20.0636C14.8472 20.4069 14.5679 20.6862 14.2246 20.6862Z"
      />
      <path
        fill="#878C87"
        d="M26.3495 19.6201H25.4973C25.2523 19.6201 25.0537 19.8187 25.0537 20.0637C25.0537 20.3088 25.2523 20.5073 25.4973 20.5073H26.3495C26.5945 20.5073 26.7931 20.3087 26.7931 20.0637C26.793 19.8187 26.5945 19.6201 26.3495 19.6201Z"
      />
      <path
        fill="#878C87"
        d="M23.7225 19.6201H17.6897C17.4447 19.6201 17.2461 19.8187 17.2461 20.0637C17.2461 20.3088 17.4447 20.5073 17.6897 20.5073H23.7225C23.9675 20.5073 24.166 20.3087 24.166 20.0637C24.166 19.8187 23.9675 19.6201 23.7225 19.6201Z"
      />
      <path
        fill="#878C87"
        d="M14.2246 23.4231C13.3921 23.4231 12.7148 24.1004 12.7148 24.9329C12.7148 25.7654 13.3921 26.4427 14.2246 26.4427C15.0571 26.4427 15.7344 25.7654 15.7344 24.9329C15.7344 24.1004 15.0571 23.4231 14.2246 23.4231ZM14.2246 25.5556C13.8813 25.5556 13.602 25.2762 13.602 24.9329C13.602 24.5896 13.8813 24.3103 14.2246 24.3103C14.5679 24.3103 14.8472 24.5896 14.8472 24.9329C14.8472 25.2762 14.5679 25.5556 14.2246 25.5556Z"
      />
      <path
        fill="#878C87"
        d="M26.3489 24.4893H17.6897C17.4447 24.4893 17.2461 24.6878 17.2461 24.9329C17.2461 25.1779 17.4447 25.3765 17.6897 25.3765H26.3489C26.594 25.3765 26.7925 25.1779 26.7925 24.9329C26.7925 24.6878 26.594 24.4893 26.3489 24.4893Z"
      />
      <path
        fill="#878C87"
        d="M14.2246 28.3635C13.3921 28.3635 12.7148 29.0408 12.7148 29.8733C12.7148 30.7058 13.3921 31.3831 14.2246 31.3831C15.0571 31.3831 15.7344 30.7058 15.7344 29.8733C15.7344 29.0408 15.0571 28.3635 14.2246 28.3635ZM14.2246 30.496C13.8813 30.496 13.602 30.2167 13.602 29.8734C13.602 29.5301 13.8813 29.2508 14.2246 29.2508C14.5679 29.2508 14.8472 29.5301 14.8472 29.8734C14.8472 30.2166 14.5679 30.496 14.2246 30.496Z"
      />
      <path
        fill="#878C87"
        d="M26.3489 29.4297H17.6897C17.4447 29.4297 17.2461 29.6283 17.2461 29.8733C17.2461 30.1183 17.4447 30.3169 17.6897 30.3169H26.3489C26.594 30.3169 26.7925 30.1183 26.7925 29.8733C26.7925 29.6283 26.594 29.4297 26.3489 29.4297Z"
      />
      <defs>
        <filter
          id="filter0_d_415_1460"
          x="0"
          y="0"
          width="44"
          height="44"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_415_1460"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_415_1460"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
