function CredentialsIcon({
  ariaLabel = "",
  width = "",
  height = "",
  fill = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill={fill}
          d="M409.386,230.638v-77.252C409.386,68.809,340.577,0,256,0S102.614,68.809,102.614,153.386v77.252
                    c-10.368,7.7-17.102,20.033-17.102,33.913v166.751c0,23.203,13.431,44.317,34.217,53.793C157.787,502.445,206.182,512,256,512
                    s98.213-9.555,136.27-26.904c20.788-9.477,34.219-30.592,34.219-53.794V264.551C426.489,250.673,419.755,238.34,409.386,230.638z
                     M118.647,153.386c0-75.736,61.616-137.353,137.353-137.353S393.353,77.65,393.353,153.386v69.939
                    c-2.929-0.646-5.967-0.995-9.086-0.995h-9.086v-68.944c0-65.717-53.465-119.182-119.182-119.182S136.818,87.67,136.818,153.386
                    v68.944h-9.086c-3.119,0-6.157,0.351-9.086,0.995V153.386z M359.148,153.386v68.944H152.852v-68.944
                    c0-56.876,46.272-103.148,103.148-103.148S359.148,96.51,359.148,153.386z M410.455,431.302c0,16.939-9.748,32.328-24.835,39.205
                    c-36.014,16.418-82.048,25.46-129.62,25.46s-93.606-9.042-129.62-25.46c-15.086-6.877-24.835-22.266-24.835-39.205V264.551
                    c0-14.44,11.748-26.188,26.188-26.188h256.534c14.44,0,26.188,11.748,26.188,26.188V431.302z"
        />
        <path
          fill={fill}
          d="M256,299.29c-23.281,0-42.221,18.941-42.221,42.221c0,13.292,6.451,25.838,17.102,33.76v26.098
                    c0,13.851,11.268,25.119,25.119,25.119c13.851,0,25.119-11.268,25.119-25.119v-26.098c10.651-7.922,17.102-20.468,17.102-33.76
                    C298.221,318.231,279.281,299.29,256,299.29z M269.07,364.043c-2.467,1.437-3.985,4.076-3.985,6.929v30.398
                    c0,5.01-4.076,9.086-9.086,9.086c-5.01,0-9.086-4.076-9.086-9.086v-30.397c0-2.854-1.517-5.493-3.985-6.929
                    c-8.213-4.781-13.117-13.204-13.117-22.532c0-14.44,11.748-26.188,26.188-26.188c14.44,0,26.188,11.748,26.188,26.188
                    C282.188,350.84,277.284,359.263,269.07,364.043z"
        />
        <path
          fill={fill}
          d="M384.267,256.534c-4.427,0-8.017,3.589-8.017,8.017v8.551c0,4.427,3.589,8.017,8.017,8.017
                    c4.427,0,8.017-3.589,8.017-8.017v-8.551C392.284,260.124,388.695,256.534,384.267,256.534z"
        />
        <path
          fill={fill}
          d="M384.267,299.29c-4.427,0-8.017,3.589-8.017,8.017v68.409c0,4.427,3.589,8.017,8.017,8.017
                    c4.427,0,8.017-3.589,8.017-8.017v-68.409C392.284,302.88,388.695,299.29,384.267,299.29z"
        />
        <path
          fill={fill}
          d="M127.733,333.495c-4.427,0-8.017,3.589-8.017,8.017v94.063c0,4.427,3.589,8.017,8.017,8.017
                    c4.427,0,8.017-3.589,8.017-8.017v-94.063C135.749,337.084,132.16,333.495,127.733,333.495z"
        />
      </g>
    </svg>
  );
}

export default CredentialsIcon;
