import { Heading, useTheme } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";

export default function SignInHeader() {
  const { tokens } = useTheme();

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Heading
        level={4}
        color="#616161"
        padding={`${tokens.space.xl} ${tokens.space.xl} 0`}
      >
        Sign in to your Account
      </Heading>
    </Box>
  );
}
