import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import CheckIcon from "@mui/icons-material/Check";

const Container = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  width: "100%", 
});

const ItemText = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

export default function SortItemMobile({ sortState, selectedSort, itemText }) {
  if (sortState.selectedSort === selectedSort) {
    return (
      <Container>
        <ItemText>{itemText}</ItemText>
        <CheckIcon />
      </Container>
    );
  } else {
    return <div>{itemText}</div>;
  }
}
