import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import AddToOrderGuideIcon from "../../components/Icons/AddToOrderGuideIcon";

export default function AddButton({ display = "block" }) {
  const theme = useTheme();

  return (
    <Link to="/add-to-order-guide">
      <AddToOrderGuideIcon
        width="40px"
        height="auto"
        fill={theme.palette.grey.main}
        style={{
          display: display,
          filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))",
        }}
      />
    </Link>
  );
}
