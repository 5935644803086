import { useEffect } from "react";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { request, gql } from "graphql-request";
import Box from "@mui/material/Box";
import LoadingSpinner from "../components/LoadingSpinner";
import CryptoJS from "crypto-js";

function CustomerStoresAPI({
  store,
  customerId,
  jwtToken,
  setTokenIsExpired,
  setCustomerStoreData,
}) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const { status, data } = useCustomerStores(
    endpoint,
    customerId,
    jwtToken,
    store
  );

  useEffect(() => {
    setCustomerStoreData(data);
  });

  useEffect(() => {
    if (status === "error") {
      setTokenIsExpired(true);
    }
  }, [status]);

  return (
    <>
      {status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}
export function useInsertStores(endpoint, customerId, jwtToken, store) {
  return useMutation(async (formData) => {
    const enckey = process.env.REACT_APP_ENCRYPT_KEY;
    console.log("enckey " + enckey);
    const pwd = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formData.password),
      enckey
    ).toString();
    console.log("pwd", pwd);
    const uid = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(formData.username),
      enckey
    ).toString();
    console.log("uid", uid);
    let variables = {
      customerId: customerId,
      store: store,
      userid: formData.username || "",
      password: formData.password || "",
      storeLocation: formData.storeLocation || null,
      status: formData.status || "",
      pwd: pwd,
      uid: uid,
    };

    const requestHeaders = {
      authorization: "Bearer " + jwtToken,
    };

    const data = await request(
      endpoint,
      gql`
        mutation UpdateStores(
          $customerId: uuid!
          $store: String!
          $userid: String!
          $password: String!
          $uid: String!
          $pwd: String!
          $storeLocation: uuid!
          $status: String!
        ) {
          insert_customer_stores(
            objects: {
              customer_id: $customerId
              store: $store
              userid: $userid
              password: $password
              uid: $uid
              pwd: $pwd
              store_location: $storeLocation
              status: $status
            }
            on_conflict: {
              constraint: customer_stores_customer_id_store_key
              update_columns: [
                userid
                password
                store_location
                status
                uid
                pwd
              ]
              where: {
                customer_id: { _eq: $customerId }
                store: { _eq: $store }
              }
            }
          ) {
            returning {
              customer_id
            }
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data.insert_customer_stores;
  });
}

function useCustomerStores(endpoint, customerId, jwtToken, store) {
  let variables = { customerId: customerId, store: store };
  const requestHeaders = {
    authorization: "Bearer " + jwtToken,
  };

  return useQuery(
    ["customerStores", customerId, store],
    async () => {
      const data = await request(
        endpoint,
        gql`
          query getStores($customerId: uuid!, $store: String!) {
            customer_stores(
              where: {
                customer_id: { _eq: $customerId }
                _and: { store: { _eq: $store } }
              }
            ) {
              id
              store
              userid
              password
              status
              store_location
            }
          }
        `,
        variables,
        requestHeaders
      );
      return data.customer_stores;
    },
    {
      cacheTime: 0,
    }
  );
}
export default CustomerStoresAPI;
