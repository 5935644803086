/*
  Generalized helper functions
  related to math.
*/

// Calculate the price per lb for an item if possible
function pricePerLb(item) {
  if (item.priceUom === "oz") {
    const price = Math.round(parseFloat(item.price) * 16 * 100) / 100;
    return addZeroes(price)
  } else {
    return null;
  }
}

// Calculate the savings per lb for a match if possible
function savingsPerLb(item) {
  if (item.priceUom === "oz") {
    const savings = Math.round(item.savingsPerUnit * 16 * 100) / 100;
    return addZeroes(savings)
  } else {
    return null;
  }
}

// Add zeros out to two decimal places for rounded numbers
function addZeroes(num) {
  const newNum = num.toString()
  const dec = newNum.split(".")[1];
  const len = dec && dec.length > 2 ? dec.length : 2;
  return Number(newNum).toFixed(len);
}

export { pricePerLb, savingsPerLb, addZeroes };
