import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    green: {
      main: "#39B54A",
    },
    blue: {
      main: "#2B5A8E",
    },
    grey: {
      main: "#878C87",
      dark: "#656964",
      light: "#EBEDEB",
    },
    text: {
      primary: "#878C87",
    },
  },
  typography: {
    fontFamily: [
      "Lato",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
