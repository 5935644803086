import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { Box, TextField } from "@mui/material";
import { Auth } from "aws-amplify";
import { H1, StyledButton } from "../Elements";
import MessageBar from "../MessageBar";
import CircularProgress from "@mui/material/CircularProgress";
import { Message } from "../Elements";

async function updateProfile(data) {
  try {
    const user = await Auth.currentAuthenticatedUser();

    await Auth.updateUserAttributes(user, {
      given_name: data.firstname,
    });
    await Auth.updateUserAttributes(user, {
      family_name: data.lastname,
    });
    await Auth.updateUserAttributes(user, {
      "custom:company": data.company,
    });
    await Auth.updateUserAttributes(user, {
      "custom:zip": data.zip,
    });
    return true;
  } catch (e) {
    return false;
  }
}

export default function Profile() {
  const theme = useTheme();
  const [alertMessage, setAlertMessage] = useState("");
  const [defaultValues, setDefaultValues] = useState({});
  const [defaultData, setDefaultData] = useState("false");

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(defaultValues);
    setTimeout(() => {
      setDefaultData(true);
    }, 200);
  }, [defaultValues]);

  useEffect(() => {
    document.title = "Profile";
    Auth.currentUserInfo().then((authData) => {
      console.log("authdata " + JSON.stringify(authData));
      setDefaultValues({
        email: authData.attributes["email"],
        firstname: authData.attributes["given_name"],
        lastname: authData.attributes["family_name"],
        company: authData.attributes["custom:company"],
        zip: authData.attributes["custom:zip"],
      });
    });
  }, []);

  const onSubmit = async (data) => {
    let saved = await updateProfile(data);
    if (saved) {
      setAlertMessage("Profile saved!");
    } else {
      setAlertMessage("Error saving profile!");
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <H1>Profile</H1>
      <MessageBar
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
      />
      {defaultData === true ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "35ch",
              marginTop: "32px",
              "& .MuiTextField-root": { marginBottom: "20px" },
              "& label": { color: theme.palette.grey.main },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.grey.main,
                },
              },
            }}
          >
            {errors.firstname?.message && (
              <Message fadeMsgIn={true} message={errors.firstname?.message} />
            )}
            <TextField
              id="firstname"
              name="firstname"
              label="First Name"
              size="small"
              {...register("firstname", { required: "First name is required" })}
              error={errors.firstname ? true : false}
              InputLabelProps={{ shrink: true }}
            />
            {errors.lastname?.message && (
              <Message fadeMsgIn={true} message={errors.lastname?.message} />
            )}
            <TextField
              id="lastname"
              name="lastname"
              label="Last Name"
              size="small"
              {...register("lastname", { required: "Last name is required" })}
              error={errors.lastname ? true : false}
              InputLabelProps={{ shrink: true }}
            />
            {errors.company?.message && (
              <Message fadeMsgIn={true} message={errors.company?.message} />
            )}
            <TextField
              id="company"
              name="company"
              label="Company"
              size="small"
              {...register("company", { required: "Company is required" })}
              error={errors.company ? true : false}
              InputLabelProps={{ shrink: true }}
            />
            {errors.company?.message && (
              <Message fadeMsgIn={true} message={errors.company?.message} />
            )}
            <TextField
              id="zipcode"
              name="zipcode"
              label="Zip Code"
              size="small"
              error={errors.zip ? true : false}
              {...register("zip", { required: "Zip code is required" })}
              InputLabelProps={{ shrink: true }}
            />
            <StyledButton id="save" type="submit" variant="contained">
              Save
            </StyledButton>
          </Box>
        </form>
      ) : (
        <Box sx={{ marginTop: 20 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}
