import { Controller } from "react-hook-form";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";

const Button = styled((props) => (
  <LoadingButton type="submit" variant="contained" {...props} />
))(({ theme }) => ({
  border: "none",
  fontSize: "var(--size-500)",
  fontWeight: 700,
  backgroundColor: theme.palette.blue.main,
  height: "40px",
  "&:hover": {
    backgroundColor: "rgba(43, 90, 142, 0.80)",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(43, 90, 142, 1, 0.80)",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
}));

export default function CredentialFormButton({
  store,
  control,
  savingCredentials,
}) {
  return (
    <Controller
      name="save"
      control={control}
      render={({ field }) => (
        <Button id={"submit-" + store} loading={savingCredentials} {...field}>
          Save
        </Button>
      )}
    />
  );
}
