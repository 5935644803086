import { Box, IconButton, Tooltip } from "@mui/material";
import { Text } from "../../components/Elements";
import OrderItemsInfoIcon from "../../components/Icons/OrderItemsInfoIcon";

export default function InvalidItem({ itemNumber, justifyContent = "none", fontStyle = "none" }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent,
        alignItems: "center",
      }}
    >
      <Text
        sx={{
          color: "#EE1240",
          fontStyle: fontStyle,
        }}
      >
        {itemNumber}
      </Text>
      <Tooltip
        arrow
        placement="top"
        title="We were not able to find current pricing for this product."
      >
        <IconButton sx={{ display: "block" }}>
          <OrderItemsInfoIcon width="15" height="auto" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
