import { styled } from "@mui/system";
import { Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordion = styled((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    border: "1px solid",
    borderColor: theme.palette.grey.main,
    borderRadius: "4px",
    backgroundColor: "#FFFFFA",
    boxShadow: "none",
    marginBottom: "16px",
  })
);

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: "2rem" }} />}
    {...props}
  />
))({
  minHeight: "24px",
  maxHeight: "40px",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(360deg)",
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  display: "flex",
  justifyContent: "center",
});

const Label = styled((props) => <Typography {...props} />)(({ theme }) => ({
  fontSize: "var(--size-500)",
  color: theme.palette.grey.dark,
}));

export default function CredentialAccordion({ label, children }) {
  const format = (str) => {
    return str.replace(/\s/g, "").toLowerCase();
  };

  return (
    <Accordion>
      <AccordionSummary
        id={`${format(label)}-header`}
        aria-controls={`${format(label)}-content`}
      >
        <Label>{label}</Label>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
