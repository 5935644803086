import { styled } from "@mui/system";
import { H1 } from "../../components/Elements";

const GridContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr",
  alignItems: "center",
  paddingBottom: "32px",
});

const Justify = styled("div")((props) => ({
  justifySelf: props.justifySelf,
}));

export default function MobileHeader({ title, firstButton, secondButton }) {
  return (
    <GridContainer>
      <Justify justifySelf="start">{firstButton}</Justify>
      <H1>{title}</H1>
      <Justify justifySelf="end">{secondButton}</Justify>
    </GridContainer>
  );
}
