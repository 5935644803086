import { Box, Link } from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Text } from "../../components/Elements";
import {
  transformVendorName,
  hasInvalidVendorCredentials,
} from "../../helpers/data.helpers";
import { pricePerLb } from "../../helpers/math.helpers";
import InvalidItem from "./InvalidItem";
import InvalidVendor from "./InvalidVendor";

const Accordion = styled((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    border: "1px solid",
    borderColor: theme.palette.grey.main,
    borderRadius: "4px",
    backgroundColor: "#FFFFFA",
    boxShadow: "none",
    marginBottom: "16px",
  })
);

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)({
  minHeight: "24px",
  maxHeight: "auto",
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(270deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(360deg)",
  },
});

const AccordionDetails = styled(MuiAccordionDetails)({
  display: "flex",
  flexDirection: "column",
});

const ItemContainer = styled("div")({
  padding: "8px",
});

const DataContainer = styled("div")((props) => ({
  display: "flex",
  alignItems: props.alignItems ? props.alignItems : "none",
}));

const Label = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
  fontStyle: "italic",
}));

const Data = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

export default function MatchAccordion({ item, customerStores, children }) {
  const format = (str) => {
    return str.replace(/\s/g, "").toLowerCase();
  };

  //
  // If the item has a url property, then that incidates that
  // it is a comp/has comps.
  //
  return (
    <Accordion>
      {item.url ? (
        <AccordionSummary
          id={`${format(item.name)}-header`}
          aria-controls={`${format(item.name)}-content`}
          expandIcon={<ExpandMoreIcon sx={{ fontSize: "2rem" }} />}
        >
          <ItemWithComps item={item} customerStores={customerStores} />
        </AccordionSummary>
      ) : (
        <Box sx={{ padding: "16px" }}>
          <ItemWithNoComps item={item} customerStores={customerStores} />
        </Box>
      )}
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

function ItemWithComps({ item, customerStores }) {
  const isExtraSmall = useMediaQuery("(max-width:800px)");

  return (
    <ItemContainer>
      {hasInvalidVendorCredentials(customerStores, item.store) ? (
        <InvalidVendor vendor={item.store} />
      ) : (
        <Label>{transformVendorName(item.store)}</Label>
      )}
      <Label>Item #: {item.storeProductId || item.productId}</Label>
      <Box sx={{ padding: "8px 0" }}>
        <Link href={item.url} target="_blank">
          <Text
            sx={{
              color: "#3E92CC",
              paddingBottom: "8px",
              maxWidth: isExtraSmall ? "30ch" : "100%",
            }}
          >
            {item.name}
          </Text>
        </Link>
        <Data>{item.rawSize}</Data>
        <Data>
          {item.rawPrice}
          {pricePerLb(item) ? (
            <span>&nbsp; (${pricePerLb(item)}/LB)</span>
          ) : null}
        </Data>
      </Box>
    </ItemContainer>
  );
}

function ItemWithNoComps({ item, customerStores }) {
  return (
    <ItemContainer>
      {hasInvalidVendorCredentials(customerStores, item.store) ? (
        <InvalidVendor vendor={item.store} />
      ) : (
        <Label>{transformVendorName(item.store)}</Label>
      )}
      <DataContainer alignItems="baseline">
        <Label>Item #:&nbsp;</Label>
        <InvalidItem
          itemNumber={item.storeProductId || item.productId}
          fontStyle="italic"
        />
      </DataContainer>
      <Box sx={{ paddingTop: "8px" }}>
        <Data>{item.name}</Data>
      </Box>
    </ItemContainer>
  );
}
