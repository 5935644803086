function ChevronIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#878C87" />
      <path
        fill="#FFFFFA"
        d="M8.00008 15.0456C7.99899 14.8569 8.03517 14.6698 8.10655 14.495C8.17792 14.3203 8.28309 14.1613 8.41602 14.0273L17.0215 5.4218C17.2916 5.15173 17.6579 5 18.0399 5C18.4218 5 18.7881 5.15173 19.0582 5.4218C19.3283 5.69188 19.48 6.05818 19.48 6.44012C19.48 6.82206 19.3283 7.18836 19.0582 7.45844L11.4566 15.0456L19.0438 22.6328C19.2788 22.9072 19.4016 23.2601 19.3876 23.6211C19.3737 23.9821 19.2241 24.3245 18.9686 24.5799C18.7132 24.8353 18.3708 24.985 18.0098 24.9989C17.6488 25.0129 17.2959 24.8901 17.0215 24.6551L8.41602 16.0496C8.15105 15.7825 8.00167 15.4219 8.00008 15.0456Z"
      />
    </svg>
  );
}

export default ChevronIcon;
