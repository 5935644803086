import { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import BeatLoader from "react-spinners/BeatLoader";
import { Text } from "../../components/Elements";
import { activeVendors } from "../../constants";
import { sortItems, transformStores } from "../../helpers/data.helpers";
import GridView from "./GridView";
import MobileView from "./MobileView";

const endpoint = process.env.REACT_APP_FOODMAVEN_API_ENDPOINT;

const Wrapper = styled("div")({
  maxWidth: "1100px",
  marginRight: "auto",
  marginLeft: "auto",
});

export default function ActiveOrderGuide({
  customerId,
  customerStores,
  orderGuideItems,
}) {
  const [items, setItems] = useState([]);
  const [filterState, setFilterState] = useState({
    isFiltered: true,
    savings: {
      percentage: 10,
      displayText: "Savings: More than 10%",
    },
    vendors: transformStores(activeVendors, customerStores),
  });

  const getMatches = async () => {
    let itemsWithMatches = await Promise.all(
      orderGuideItems.map(async (item) => {
        let newItem = await fetch(
          `${endpoint}/browser-extension/ogcomp/get?pid=${item.storeProductId}&store=${item.store}&cid=${customerId}&msp=${filterState.savings.percentage}`
        )
          .then((response) => response.json())
          .then((data) => {
            //
            // If there is no comp for the original item, return the original item
            // and an empty object of matches
            //
            // else
            //
            // We need to turn the match data into an object with the associated
            // vendor names as a key instead of an array. This is so that the match
            // can fall under the correct vendor column when we are displaying the
            // grid view
            //
            // The object is instantiated with a default value of null for each
            // vendor the customer has. It will remain null if there are no
            // product matches returned for that vendor.
            //
            if (data.originalComp.id === null) {
              return { ...item, matches: {} };
            } else {
              const sortedStores = customerStores
                .map((obj) => obj.store.toLowerCase())
                .sort()
                .filter((vendor) => activeVendors.includes(vendor));

              const matches = {};
              sortedStores.forEach((store) => {
                matches[store] = null;
              });

              data.matchingComps.forEach((match) => {
                if (match.store.toLowerCase() in matches) {
                  matches[match.store.toLowerCase()] = match;
                }
              });

              return { ...data.originalComp, matches: matches };
            }
          });
        return newItem;
      })
    );

    const mostMatchesFirst = (a, b) => {
      let countA = 0;
      let countB = 0;

      Object.values(a.matches).forEach((match) => {
        if (match === null) {
          countA += 1;
        }
      });
      Object.values(b.matches).forEach((match) => {
        if (match === null) {
          countB += 1;
        }
      });

      return countA - countB;
    };

    return sortItems(itemsWithMatches, mostMatchesFirst);
  };

  useEffect(() => {
    setItems([]);
    getMatches().then(
      (data) => setItems(data),
      (error) => console.log(error)
    );
  }, [filterState.savings]);

  return (
    <Wrapper>
      {items.length === 0 ? (
        <Loading />
      ) : (
        <Display
          items={items}
          customerStores={customerStores}
          filterState={filterState}
          setFilterState={setFilterState}
        />
      )}
    </Wrapper>
  );
}

function Loading() {
  return (
    <Box sx={{ textAlign: "center", paddingTop: "80px" }}>
      <Text sx={{ fontSize: "1.5rem", paddingBottom: "64px" }}>
        One moment while we build your order guide.
      </Text>
      <BeatLoader />
    </Box>
  );
}

function Display({ items, customerStores, filterState, setFilterState }) {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const memoizedItems = useMemo(() => [...items], [items]);

  const [sortState, setSortState] = useState({
    items: items,
    text: "Sort By",
    isSorted: false,
    selectedSort: null,
  });

  if (isMobile) {
    return (
      <MobileView
        items={items}
        memoizedItems={memoizedItems}
        customerStores={customerStores}
        sortState={sortState}
        setSortState={setSortState}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    );
  } else {
    return (
      <GridView
        items={items}
        memoizedItems={memoizedItems}
        customerStores={customerStores}
        sortState={sortState}
        setSortState={setSortState}
        filterState={filterState}
        setFilterState={setFilterState}
      />
    );
  }
}
