import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { getOrderGuideItems } from "../../api/OrderGuideAPI";
import ReadyToSetup from "./ReadyToSetup";
import ActiveOrderGuide from "./ActiveOrderGuide";

export default function OrderGuideDisplay({ customerId, customerStores }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [state, setState] = useState({
    status: "pending",
    orderGuideItems: [],
  });

  useEffect(() => {
    if (customerId) {
      const jwt = user.signInUserSession.idToken.jwtToken;

      getOrderGuideItems(jwt, customerId).then((data) => {
        setState({
          status: "resolved",
          orderGuideItems: data,
        });
      });
    }
  }, [customerId]);

  if (state.status === "pending") {
    return null;
  } else if (state.orderGuideItems.length === 0) {
    return <ReadyToSetup />;
  } else {
    return (
      <ActiveOrderGuide
        customerId={customerId}
        customerStores={customerStores}
        orderGuideItems={state.orderGuideItems}
      />
    );
  }
}
