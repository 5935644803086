import { forwardRef, useState } from "react";
import { styled } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import PropTypes from "prop-types";
import clsx from "clsx";
import DrawerList from "./DrawerList";
import HamburgerMenuIcon from "./Icons/HamburgerMenuIcon";
import CloseNavIcon from "./Icons/CloseNavIcon";

const StickyContainer = styled("div")({
  position: "sticky",
  top: 0,
  zIndex: 4400,
});

const ComponentContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "1rem",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
});

const BackdropUnstyled = forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});

BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

const Modal = styled(ModalUnstyled)({
  position: "fixed",
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ModalBackdrop = styled(BackdropUnstyled)({
  zIndex: -1,
  position: "fixed",
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: "white",
});

const ModalContainer = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
});

const ImgWrapper = styled("div")({
  marginRight: "auto",
  marginLeft: "auto",
});

const ImgContainer = styled(Box)({
  width: "182px",
  height: "auto",
});

const Header = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: "1rem",
});

const Nav = styled("nav")({
  marginLeft: "auto",
  marginRight: "auto",
  paddingLeft: "24px",
  paddingRight: "24px",
});

export default function TopNav({ isSelected, setIsSelected }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <StickyContainer>
      <ComponentContainer>
        <Box onClick={handleOpen} sx={{ cursor: "pointer" }}>
          <HamburgerMenuIcon
            width="32px"
            height="auto"
            fill={theme.palette.grey.main}
          />
        </Box>
        <ImgWrapper>
          <ImgContainer sx={{ marginLeft: "-32px" }}>
            <img src="./foodmavenLogo.png" alt="FoodMaven brand logo" />
          </ImgContainer>
        </ImgWrapper>
        <Modal
          open={open}
          aria-labelledby="Navigation menu"
          aria-describedby="Use this menu to select a new page"
        >
          <ModalBackdrop>
            <ModalContainer>
              <Header>
                <ImgWrapper>
                  <ImgContainer sx={{ marginRight: "-32px" }}>
                    <img src="./foodmavenLogo.png" alt="FoodMaven brand logo" />
                  </ImgContainer>
                </ImgWrapper>
                <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                  <CloseNavIcon
                    width="32px"
                    height="auto"
                    fill={theme.palette.grey.main}
                  />
                </Box>
              </Header>
              <Nav>
                <DrawerList
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  closeTopNavModal={handleClose}
                />
              </Nav>
            </ModalContainer>
          </ModalBackdrop>
        </Modal>
      </ComponentContainer>
    </StickyContainer>
  );
}
