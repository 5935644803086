import { Heading, useTheme } from "@aws-amplify/ui-react";

export default function ForgotHeader() {
  const { tokens } = useTheme();

  return (
    <Heading level={4} color="#616161">
      Reset your password
    </Heading>
  );
}
