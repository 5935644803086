import { useEffect } from "react";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import Box from "@mui/material/Box";
import LoadingSpinner from "../components/LoadingSpinner";

function StoreLocationsAPI({ setStoreLocationsData, storeName, jwtToken }) {
  const endpoint = process.env.REACT_APP_CUSTOMER_API_ENDPOINT;

  const { status, data } = useStoreLocations(endpoint, storeName, jwtToken);

  useEffect(() => {
    setStoreLocationsData(data);
  });

  return (
    <>
      {status === "loading" ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : null}
    </>
  );
}

function useStoreLocations(endpoint, storeName, jwtToken) {
  let variables = { storeName: storeName };
  const requestHeaders = {
    authorization: "Bearer " + jwtToken,
  };

  return useQuery(["storeLocations", storeName], async () => {
    const data = await request(
      endpoint,
      gql`
        query getStores($storeName: String!) {
          store_locations(
            where: { store_name: { _eq: $storeName } }
            order_by: { state: asc, city: asc }
          ) {
            id
            store_name
            city
            state
            zip
          }
        }
      `,
      variables,
      requestHeaders
    );
    return data.store_locations;
  });
}
export default StoreLocationsAPI;
