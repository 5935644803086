import { useState, useEffect, cloneElement } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import { ListItemIcon, ListItemText } from "@mui/material";

const LinkItem = styled(Link)({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  paddingTop: "0px",
  paddingBottom: "0px",
  marginBottom: "16px",
});

const DecorativeBox = styled("div")({
  width: "28px",
  height: "20px",
  border: "none",
  borderRadius: "4px",
  marginLeft: "-40px",
  marginRight: "12px",
});

export default function DrawerItem({
  to,
  text,
  isLogout = false,
  isSelected,
  setIsSelected,
  closeModal,
  children,
}) {
  const theme = useTheme();
  const [colors, setColors] = useState({
    item: theme.palette.grey.main,
    box: "none",
  });

  useEffect(() => {
    if (isSelected) {
      setColors({
        item: theme.palette.green.main,
        box: theme.palette.green.main,
      });
    }
  }, []);

  const handleClick = () => {
    if (!isLogout) {
      closeModal();
      setIsSelected();
    }
  };

  const handleMouseEnter = () => {
    if (isSelected) {
      return;
    } else {
      setColors({
        item: theme.palette.green.main,
        box: theme.palette.green.main,
      });
    }
  };

  const handleMouseLeave = () => {
    if (isSelected) {
      return;
    } else {
      setColors({ item: theme.palette.grey.main, box: "none" });
    }
  };

  return (
    <LinkItem
      to={to}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <DecorativeBox sx={{ backgroundColor: colors.box }} />
      <ListItemIcon sx={{ minWidth: "62px" }}>
        {cloneElement(children, { fill: colors.item })}
      </ListItemIcon>
      <ListItemText
        primary={text}
        primaryTypographyProps={{
          color: colors.item,
          fontSize: "var(--size-600)",
          fontWeight: 700,
        }}
      />
    </LinkItem>
  );
}
