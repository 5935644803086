/*
  App-wide constants.
*/

const activeVendors = [
  "sysco",
  "us foods",
  "shamrock",
  "restaurant depot",
  "what chef's want",
]

export { activeVendors }
