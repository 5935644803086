import { Link } from "@mui/material";
import { styled } from "@mui/system";
import { Text } from "../../components/Elements";
import { pricePerLb, savingsPerLb } from "../../helpers/math.helpers";

const DataContainer = styled("div")({
  display: "flex",
});

const Data = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.grey.main,
}));

const LinkText = styled((props) => <Text {...props} />)({
  color: "#3E92CC",
});

const Savings = styled((props) => <Text {...props} />)(({ theme }) => ({
  color: theme.palette.green.main,
  fontStyle: "bold",
}));

export default function GridMatch({ item }) {
  return (
    <>
      {!item ? (
        <div></div>
      ) : (
        <>
          <Link href={item.url} target="_blank">
            <LinkText>{item.name}</LinkText>
          </Link>
          <DataContainer>
            <Text>Pack/Size:&nbsp;</Text>
            <Data>{item.rawSize}</Data>
          </DataContainer>
          <DataContainer>
            <Text>Price:&nbsp;</Text>
            <Data>{item.rawPrice}</Data>
            {pricePerLb(item) ? (
              <Data>&nbsp; (${pricePerLb(item)}/LB)</Data>
            ) : null}
          </DataContainer>
          <Savings>
            {Math.round(item.savingsPct * 10) / 10}% savings
            {savingsPerLb(item) ? (
              <span>&nbsp; (${savingsPerLb(item)}/LB)</span>
            ) : null}
          </Savings>
        </>
      )}
    </>
  );
}
