import {
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/system";
import { transformVendorName } from "../../helpers/data.helpers";
import DesktopHeader from "./DesktopHeader";
import AddButton from "./AddButton";
import RemoveButton from "./RemoveButton";
import Sort from "./Sort";
import Filter from "./Filter";
import Vendors from "./Vendors";
import GridItem from "./GridItem";
import GridMatch from "./GridMatch";

const StyledHeaderCell = styled((props) => (
  <TableCell align="center" {...props} />
))({
  fontSize: "1.2rem",
});

const StyledTableCell = styled((props) => <TableCell {...props} />)({
  width: "40ch",
  borderTop: "1px solid",
  borderRight: "1px solid",
  borderColor: "#EBEDEB",
});

const FiltersContainer = styled("div")({
  display: "flex",
  paddingBottom: "32px",
});

const PadFilter = styled("div")({
  paddingLeft: "16px",
});

export default function GridView({
  items,
  memoizedItems,
  customerStores,
  sortState,
  setSortState,
  filterState,
  setFilterState,
}) {
  return (
    <>
      <DesktopHeader
        title="Order Guide"
        firstButton={<AddButton />}
        secondButton={<RemoveButton />}
        buttonSpacing="16px"
        paddingBottom="16px"
      />
      <FiltersContainer>
        <Sort
          items={items}
          memoizedItems={memoizedItems}
          sortState={sortState}
          setSortState={setSortState}
        />
        <PadFilter>
          <Filter filterState={filterState} setFilterState={setFilterState} />
        </PadFilter>
        <PadFilter>
          <Vendors filterState={filterState} setFilterState={setFilterState} />
        </PadFilter>
      </FiltersContainer>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>Items</StyledHeaderCell>
              {Object.keys(filterState.vendors).map((vendor, index) => (
                filterState.vendors[vendor].display ? (
                  <StyledHeaderCell key={index}>
                    {transformVendorName(vendor)}
                  </StyledHeaderCell>
                ) : (
                  null
                )
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortState.items.map((item) => (
              <TableRow key={item.id}>
                <StyledTableCell>
                  <GridItem item={item} customerStores={customerStores} />
                </StyledTableCell>
                {Object.keys(item.matches).map((vendor, index) => (
                  filterState.vendors[vendor].display ? (
                    <StyledTableCell key={index}>
                      <GridMatch item={item.matches[vendor]} />
                    </StyledTableCell>
                  ) : (
                    null
                  )
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
