import { styled, useTheme } from "@mui/system";
import { Fade, Button, Box, TextField, Typography } from "@mui/material";

const H1 = styled((props) => <Typography component="h1" {...props} />)(
  ({ theme }) => ({
    fontSize: "clamp(var(--size-800), 6vw, var(--size-900))",
    fontStyle: "italic",
    fontWeight: 900,
    letterSpacing: "0.04rem",
    color: theme.palette.grey.dark,
  })
);

const H2 = styled((props) => <Typography component="h2" {...props} />)(
  ({ theme }) => ({
    fontSize: "var(--size-600)",
    fontWeight: 700,
    color: theme.palette.grey.dark,
    textDecoration: "underline",
    marginTop: "16px",
    marginBottom: "16px",
  })
);

const H3 = styled((props) => <Typography component="h3" {...props} />)(
  ({ theme }) => ({
    fontSize: "18px",
    fontWeight: 700,
    color: theme.palette.grey.dark,
  })
);

const Text = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

const StyledTextField = styled((props) => (
  <TextField
    variant="outlined"
    size="small"
    margin="dense"
    InputLabelProps={{ shrink: true }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontFamily: "inherit",
  },
  "& .MuiInputBase-input": {
    "&:focus": {
      color: theme.palette.grey.dark,
    },
  },
  "& label": {
    color: theme.palette.grey.main,
  },
  "& label.Mui-focused": {
    color: theme.palette.grey.dark,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: theme.palette.grey.dark,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey.main,
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey.main,
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.grey.dark,
    },
  },
}));

const StyledButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  border: "none",
  fontSize: "var(--size-500)",
  fontWeight: 700,
  color: "white",
  backgroundColor: theme.palette.blue.main,
  "&:hover": {
    backgroundColor: "rgba(43, 90, 142, 0.80)",
    boxShadow: "none",
  },
  "&:active": {
    backgroundColor: "rgba(43, 90, 142, 1, 0.80)",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
  "&.Mui-disabled": {
    color: "white",
    backgroundColor: "#CACDCA",
    boxShadow: "none",
  },
}));
function Message({ fadeMsgIn, message }) {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <p>{null}</p>
      {message ? (
        <Fade timeout={{ exit: 3000 }} in={fadeMsgIn}>
          <Typography
            sx={{
              color: theme.palette.error.main,
            }}
          >
            {message}
          </Typography>
        </Fade>
      ) : (
        <p>{null}</p>
      )}
    </Box>
  );
}

export { H1, H2, H3, StyledButton, StyledTextField, Text, Message };
