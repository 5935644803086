import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Text } from "../../components/Elements";
import { transformVendorName } from "../../helpers/data.helpers";
import OrderItemsInfoIcon from "../../components/Icons/OrderItemsInfoIcon";

const StyledLink = styled(Link)({
  textDecoration: "none",
});

export default function InvalidVendor({ vendor, justifyContent = "none" }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: justifyContent,
        alignItems: "center",
      }}
    >
      <StyledLink to="/credentials">
        <Text
          sx={{
            color: "#EE1240",
            fontStyle: "italic",
          }}
        >
          {transformVendorName(vendor)}
        </Text>
      </StyledLink>
      <Tooltip
        arrow
        placement="top"
        title="Update your login information for this vendor."
      >
        <IconButton sx={{ display: "block" }}>
          <OrderItemsInfoIcon width="15" height="auto" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
