import { Heading, useTheme } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";

export default function SignUpHeader() {
  const { tokens } = useTheme();

  return (
    <Box sx={{ textAlign: "center" }}>
      <Heading
        level={4}
        color="#616161"
        padding={`${tokens.space.xl} ${tokens.space.xl} 0`}
      >
        Let's get you setup
      </Heading>
    </Box>
  );
}
