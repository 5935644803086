import { Link } from "react-router-dom";
import ReturnToOrderGuideIcon from "../../components/Icons/ReturnToOrderGuideIcon";

export default function ReturnButton() {
  return (
    <Link to="/order-guide">
      <ReturnToOrderGuideIcon width="44px" height="auto" />
    </Link>
  );
}
