function OrderGuideIcon({ ariaLabel = "", width = "", height = "", fill = "" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-labelledby={ariaLabel}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M35.6686 17.252C35.9953 17.252 36.26 16.9873 36.26 16.6605V3.79969C36.26 1.70469 34.5557 0.000234375 32.4606 0.000234375C31.1086 0.000234375 9.13738 0 7.94949 0C5.62801 0 3.73926 1.88867 3.73926 4.21023V38.3687C3.73926 39.2681 4.47105 40 5.37066 40H28.1732C29.0727 40 29.8046 39.2683 29.8046 38.3687V27.7238H34.7875C35.5996 27.7238 36.2602 27.0631 36.2602 26.2512V19.0263C36.2602 18.6996 35.9954 18.4348 35.6688 18.4348C35.3421 18.4348 35.0773 18.6996 35.0773 19.0263V26.2512C35.0773 26.4109 34.9473 26.5409 34.7875 26.5409H29.8046C29.8044 25.5882 29.8016 5.5343 29.8014 3.79977C29.8014 2.53875 30.6982 1.4832 31.8875 1.23727C33.5729 0.889844 35.0773 2.16133 35.0773 3.79977V16.6606C35.0771 16.9872 35.342 17.252 35.6686 17.252ZM28.9145 2.32938C28.4828 3.35437 28.6196 2.85664 28.6183 27.1323C28.6183 27.1455 28.6215 38.3551 28.6215 38.3687C28.6215 38.6159 28.4203 38.8171 28.1731 38.8171H5.37059C5.12324 38.8171 4.92215 38.6159 4.92215 38.3687V4.21023C4.92215 2.54094 6.2802 1.18289 7.94949 1.18289H29.6642C29.3598 1.50312 29.096 1.89883 28.9145 2.32938Z"
      />
      <path
        fill={fill}
        d="M9.63324 4.89722C8.52324 4.89722 7.62012 5.80026 7.62012 6.91026C7.62012 8.02026 8.52324 8.92331 9.63324 8.92331C10.7432 8.92331 11.6463 8.02026 11.6463 6.91026C11.6463 5.80026 10.7432 4.89722 9.63324 4.89722ZM9.63324 7.74042C9.17551 7.74042 8.80309 7.368 8.80309 6.91026C8.80309 6.45253 9.17543 6.08011 9.63324 6.08011C10.0911 6.08011 10.4634 6.45253 10.4634 6.91026C10.4634 7.368 10.0909 7.74042 9.63324 7.74042Z"
      />
      <path
        fill={fill}
        d="M15.3887 6.31885H14.2526C13.9259 6.31885 13.6611 6.58361 13.6611 6.91033C13.6611 7.23705 13.9259 7.50182 14.2526 7.50182H15.3887C15.7154 7.50182 15.9802 7.23705 15.9802 6.91033C15.9802 6.58354 15.7154 6.31885 15.3887 6.31885Z"
      />
      <path
        fill={fill}
        d="M25.7983 6.31885H17.7546C17.4279 6.31885 17.1631 6.58361 17.1631 6.91033C17.1631 7.23705 17.4279 7.50182 17.7545 7.50182H25.7982C26.1249 7.50182 26.3896 7.23705 26.3896 6.91033C26.3897 6.58354 26.125 6.31885 25.7983 6.31885Z"
      />
      <path
        fill={fill}
        d="M9.63316 11.4844C8.52316 11.4844 7.62012 12.3874 7.62012 13.4974C7.62012 14.6074 8.52316 15.5105 9.63316 15.5105C10.7432 15.5105 11.6462 14.6074 11.6462 13.4974C11.6462 12.3874 10.7432 11.4844 9.63316 11.4844ZM9.63316 14.3277C9.17535 14.3277 8.80301 13.9552 8.80301 13.4975C8.80301 13.0398 9.17543 12.6673 9.63316 12.6673C10.0909 12.6673 10.4633 13.0398 10.4633 13.4975C10.4633 13.9552 10.0908 14.3277 9.63316 14.3277Z"
      />
      <path
        fill={fill}
        d="M25.7982 12.906H14.2526C13.9259 12.906 13.6611 13.1708 13.6611 13.4974C13.6611 13.8241 13.9259 14.0889 14.2526 14.0889H25.7982C26.125 14.0889 26.3897 13.8241 26.3897 13.4974C26.3897 13.1708 26.125 12.906 25.7982 12.906Z"
      />
      <path
        fill={fill}
        d="M9.63316 18.0718C8.52316 18.0718 7.62012 18.9748 7.62012 20.0848C7.62012 21.1948 8.52316 22.0979 9.63316 22.0979C10.7432 22.0979 11.6462 21.1948 11.6462 20.0848C11.6462 18.9748 10.7432 18.0718 9.63316 18.0718ZM9.63316 20.9151C9.17535 20.9151 8.80301 20.5427 8.80301 20.085C8.80301 19.6272 9.17543 19.2548 9.63316 19.2548C10.0909 19.2548 10.4633 19.6272 10.4633 20.085C10.4633 20.5427 10.0908 20.9151 9.63316 20.9151Z"
      />
      <path
        fill={fill}
        d="M25.7989 19.4934H24.6628C24.3361 19.4934 24.0713 19.7582 24.0713 20.0849C24.0713 20.4116 24.3361 20.6763 24.6628 20.6763H25.7989C26.1257 20.6763 26.3904 20.4115 26.3904 20.0849C26.3904 19.7582 26.1257 19.4934 25.7989 19.4934Z"
      />
      <path
        fill={fill}
        d="M22.2963 19.4934H14.2526C13.9259 19.4934 13.6611 19.7582 13.6611 20.0849C13.6611 20.4116 13.9259 20.6763 14.2526 20.6763H22.2963C22.623 20.6763 22.8877 20.4115 22.8877 20.0849C22.8877 19.7582 22.623 19.4934 22.2963 19.4934Z"
      />
      <path
        fill={fill}
        d="M9.63316 24.564C8.52316 24.564 7.62012 25.467 7.62012 26.577C7.62012 27.687 8.52316 28.5901 9.63316 28.5901C10.7432 28.5901 11.6462 27.687 11.6462 26.577C11.6462 25.467 10.7432 24.564 9.63316 24.564ZM9.63316 27.4072C9.17535 27.4072 8.80301 27.0348 8.80301 26.5771C8.80301 26.1194 9.17543 25.7469 9.63316 25.7469C10.0909 25.7469 10.4633 26.1194 10.4633 26.5771C10.4633 27.0348 10.0908 27.4072 9.63316 27.4072Z"
      />
      <path
        fill={fill}
        d="M25.7982 25.9856H14.2526C13.9259 25.9856 13.6611 26.2504 13.6611 26.5771C13.6611 26.9038 13.9259 27.1686 14.2526 27.1686H25.7982C26.125 27.1686 26.3897 26.9038 26.3897 26.5771C26.3897 26.2504 26.125 25.9856 25.7982 25.9856Z"
      />
      <path
        fill={fill}
        d="M9.63316 31.1514C8.52316 31.1514 7.62012 32.0544 7.62012 33.1644C7.62012 34.2744 8.52316 35.1775 9.63316 35.1775C10.7432 35.1775 11.6462 34.2744 11.6462 33.1644C11.6462 32.0543 10.7432 31.1514 9.63316 31.1514ZM9.63316 33.9946C9.17535 33.9946 8.80301 33.6222 8.80301 33.1645C8.80301 32.7068 9.17543 32.3343 9.63316 32.3343C10.0909 32.3343 10.4633 32.7068 10.4633 33.1645C10.4633 33.6221 10.0908 33.9946 9.63316 33.9946Z"
      />
      <path
        fill={fill}
        d="M25.7982 32.573H14.2526C13.9259 32.573 13.6611 32.8378 13.6611 33.1645C13.6611 33.4912 13.9259 33.756 14.2526 33.756H25.7982C26.125 33.756 26.3897 33.4912 26.3897 33.1645C26.3897 32.8378 26.125 32.573 25.7982 32.573Z"
      />
    </svg>
  );
}

export default OrderGuideIcon;
