import { useState, useEffect } from "react";
import { I18n } from "aws-amplify";
import {
  Authenticator,
  CheckboxField,
  Image,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Grid } from "@mui/material";
import "./Auth.css";
import Home from "../Home";
import Header from "./Header";
import SignInHeader from "./SignInHeader";
import SignUpHeader from "./SignUpHeader";
import ForgotHeader from "./ForgotHeader";

const components = {
  Header,
  SignIn: {
    Header: SignInHeader,
  },
  SignUp: {
    Header: SignUpHeader,
    FormFields() {
      return (
        <>
          {/* Re-use default `Authenticator.SignUp.FormFields` */}
          <Authenticator.SignUp.FormFields />

          {/* Append & require Terms & Conditions field to sign up  */}
          <ControlledCheckbox />
        </>
      );
    },
  },
  ResetPassword: {
    Header: ForgotHeader,
  },
};

function ControlledCheckbox() {
  const { validationErrors } = useAuthenticator();
  const [checked, setChecked] = useState(false);

  return (
    <Box sx={{ fontSize: "var(--size-300)" }}>
      <Box sx={{ paddingBottom: "16px" }}>
        <p>
          By creating an account you agree to our{" "}
          <a target="_blank" href="https://www.foodmaven.com/terms-of-service">
            Terms
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://www.foodmaven.com/privacy">
            Privacy Policy
          </a>
        </p>
      </Box>
      <CheckboxField
        name="acknowledgement"
        value="yes"
        label="I agree to receive email communication about my FoodMaven account."
        checked={checked}
        hasError={!!validationErrors.acknowledgement}
        errorMessage={validationErrors.acknowledgement}
        onChange={(e) => setChecked(e.target.checked)}
      />
    </Box>
  );
}

export default function Auth({ initialState = "signIn" }) {
  const wideScreen = useMediaQuery("(min-width:1000px)");
  const { tokens } = useTheme();
  I18n.setLanguage("en");
  I18n.putVocabulariesForLanguage("en", {
    "Attributes did not conform to the": "Wrong",
  });
  const formFields = {
    signUp: {
      given_name: {
        order: 1,
        placeholder: "First Name",
        isRequired: true,
      },
      family_name: {
        order: 2,
        placeholder: "Last Name",
        isRequired: true,
      },
      "custom:company": {
        order: 3,
        placeholder: "Company",
        isRequired: true,
      },
      "custom:zip": {
        order: 4,
        placeholder: "Zip",
        isRequired: true,
        hint: "Enter 5 digit zip",
      },
      email: {
        order: 5,
        placeholder: "Email",
        isRequired: true,
      },
      password: {
        order: 6,
        placeholder: "Password",
        isRequired: true,
      },
      confirm_password: {
        order: 7,
        placeholder: "Confirm Password",
        isRequired: true,
      },
    },
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Box>
          <Grid container alignItems="center" justify="center">
            <Grid item>
              <Box padding="30px">
                <Image
                  src="https://cdn.foodmaven.com/img/fm-logo.png"
                  width="300px"
                />
              </Box>
            </Grid>
            {wideScreen ? (
              <>
                <Grid item>
                  <Box
                    width="5px"
                    height="40px"
                    bgcolor="#39b54a"
                    marginLeft="25px"
                    marginRight="25px"
                  ></Box>
                </Grid>
                <Grid item>
                  <Box
                    padding="30px"
                    sx={{
                      fontWeight: 900,
                      fontStyle: "italic",
                      fontSize: "45px",
                      color: "#616161",
                    }}
                  >
                    Smart Shopping Assistant
                  </Box>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Box>
        <Box>
          <Grid container spacing={0} alignItems="center" justify="center">
            {wideScreen ? (
              <>
                <Grid item>
                  <Box
                    className="font-face-gm"
                    sx={{
                      borderRadius: 10,
                      border: 2,
                      marginLeft: "0px",
                      marginRight: "60px",
                      marginTop: "60px",
                      marginBottom: "60px",
                      padding: "20px",
                      borderTopColor: "#39B54A",
                      borderBottomColor: "#39B54A",
                      borderLeftColor: "#39B54A",
                      borderRightColor: "#39B54A",
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#616161",
                      textAlign: "center",
                      boxShadow: 4,
                    }}
                  >
                    Find up to 8% savings on food costs
                  </Box>
                  <Box
                    className="font-face-gm"
                    sx={{
                      borderRadius: 10,
                      border: 2,
                      marginLeft: "0px",
                      marginRight: "60px",
                      marginTop: "60px",
                      marginBottom: "60px",
                      padding: "20px",
                      borderTopColor: "#39B54A",
                      borderBottomColor: "#39B54A",
                      borderLeftColor: "#39B54A",
                      borderRightColor: "#39B54A",
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#616161",
                      textAlign: "center",
                      boxShadow: 4,
                    }}
                  >
                    Five minutes to setup
                  </Box>
                  <Box
                    className="font-face-gm"
                    sx={{
                      borderRadius: 10,
                      border: 2,
                      marginLeft: "0px",
                      marginRight: "60px",
                      marginTop: "60px",
                      marginBottom: "60px",
                      padding: "20px",
                      borderTopColor: "#39B54A",
                      borderBottomColor: "#39B54A",
                      borderLeftColor: "#39B54A",
                      borderRightColor: "#39B54A",
                      fontFamily: "Lato",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#616161",
                      textAlign: "center",
                      boxShadow: 4,
                    }}
                  >
                    Completely free
                  </Box>
                </Grid>
              </>
            ) : null}
            <Grid item>
              <Box>
                <Authenticator
                  components={components}
                  formFields={formFields}
                  initialState={initialState}
                  isDisabled={false}
                  services={{
                    async validateCustomSignUp(formData) {
                      if (!formData.acknowledgement) {
                        return {
                          acknowledgement:
                            "You must agree to receive email communication about your FoodMaven account.",
                        };
                      }
                      if (formData["custom:zip"]) {
                        let isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(
                          formData["custom:zip"]
                        );
                        if (isValidZip) {
                          return;
                        } else {
                          return {
                            "custom:zip": "Zip must be 5 digits",
                          };
                        }
                      }
                    },
                  }}
                >
                  {({ signOut, user }) => <Home />}
                </Authenticator>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
}
