import { Controller } from "react-hook-form";
import { FormControl } from "@mui/material";
import { StyledTextField } from "../../Elements";
import StoreLocations from "./StoreLocations";

// There are a bunch of hacks in the JSX to try to prevent autofill by password managers
export default function CredentialFormInputs({
  control,
  store,
  storeUrl,
  watchUsername,
  storeLocation,
  customerId,
  emailRequired,
  jwtToken,
}) {
  return (
    <>
      <Controller
        name="username"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            id={"username-" + store}
            name="username"
            label="Username"
            error={!!error}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            {...field}
          />
        )}
        rules={
          emailRequired
            ? {
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Must enter a valid email address",
                },
              }
            : null
        }
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <StyledTextField
            sx={{ fontFamily: "password" }}
            id={"password-" + store}
            name="password"
            label="Password"
            error={!!error}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            {...field}
          />
        )}
        rules={{
          validate: (pwd) => {
            if (watchUsername) {
              if (pwd) {
                return true;
              } else {
                return false;
              }
            }
          },
        }}
      />
      {store === "Restaurant Depot" ? (
        <FormControl>
          <StoreLocations
            control={control}
            storeLocation={storeLocation}
            customerId={customerId}
            jwtToken={jwtToken}
          />
        </FormControl>
      ) : (
        <Controller
          name="store"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              id={"store-" + store}
              name="store"
              label="Store"
              error={!!error}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <a
                    href={storeUrl}
                    target="_blank"
                    style={{
                      whiteSpace: "nowrap",
                      color: "#878C87",
                    }}
                    rel="noreferrer"
                  >
                    {store}&nbsp;Website
                  </a>
                ),
              }}
              {...field}
            />
          )}
          rules={{
            validate: (pwd) => {
              if (watchUsername) {
                if (pwd) {
                  return true;
                } else {
                  return false;
                }
              }
            },
          }}
        />
      )}
    </>
  );
}
