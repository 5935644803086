import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import RemoveFromOrderGuideIcon from "../../components/Icons/RemoveFromOrderGuideIcon";

const DisabledLink = styled("div")({
  cursor: "not-allowed",
});

export default function RemoveButton({ enabled = true }) {
  return (
    <>
      {enabled ? (
        <Link to="/remove-from-order-guide">
          <RemoveFromOrderGuideIcon
            width="44px"
            height="auto"
            enabled={enabled}
          />
        </Link>
      ) : (
        <DisabledLink>
          <RemoveFromOrderGuideIcon
            width="44px"
            height="auto"
            enabled={enabled}
          />
        </DisabledLink>
      )}
    </>
  );
}
