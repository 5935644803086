import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/system";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Text } from "../../components/Elements";
import { transformVendorName } from "../../helpers/data.helpers";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const FilterButton = styled((props) => (
  <button id="filter-button" aria-haspopup="true" {...props}></button>
))({
  display: "flex",
  border: "1.5px solid #878C87",
  borderRadius: "16px",
  cursor: "pointer",
  padding: "4px 8px",
});

const FilterTextContainer = styled("span")({
  paddingRight: "8px",
});

const SelectButton = styled((props) => <button {...props}></button>)({
  display: "flex",
  border: "1.5px solid #878C87",
  borderRadius: "16px",
  cursor: "pointer",
  padding: "4px 8px",
});

const StyledMenuItem = styled((props) => <MenuItem {...props} />)({
  "&:hover": {
    color: "#FFFFFF",
    backgroundColor: "#878C87",
  },
});

export default function Filter({ filterState, setFilterState }) {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selected, setSelected] = useState({
    savingsPercentage: filterState.savings.percentage,
    vendors: { ...filterState.vendors },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSavingsSelection = (event) => {
    const newPercentage = parseInt(
      event.target.getAttribute("data-savings-percentage")
    );

    setSelected({
      ...selected,
      savingsPercentage: newPercentage,
    });
  };

  const toggleVendorSelection = (event) => {
    const vendor = event.target.innerText.toLowerCase();

    // Toggle boolean
    selected.vendors[vendor].isSelectedOnMobile =
      !selected.vendors[vendor].isSelectedOnMobile;

    setSelected({ ...selected });
  };

  const resetFilter = (event) => {
    event.stopPropagation();
    handleClose();

    // Reset vendor display state
    Object.keys(selected.vendors).forEach((vendorName) => {
      const vendor = selected.vendors[vendorName];

      vendor.display = true;
      vendor.isSelectedOnMobile = true;
    });

    setFilterState({
      isFiltered: true,
      savings: {
        percentage: 10,
        displayText: "Savings",
      },
      vendors: selected.vendors,
    });
  };

  const applyFilter = (event) => {
    handleClose();

    const oldVendorState = selected.vendors;
    const newVendorState = {};
    Object.keys(oldVendorState).forEach((vendor) => {
      if (oldVendorState[vendor].isSelectedOnMobile === false) {
        newVendorState[vendor] = { ...oldVendorState[vendor], display: false };
      } else {
        newVendorState[vendor] = { ...oldVendorState[vendor], display: true };
      }
    });

    let percentage;
    let displayText;
    if (isMobile) {
      percentage = selected.savingsPercentage;
      displayText = `Savings: More than ${selected.savingsPercentage}%`;
    } else {
      const newPercentage = parseInt(
        event.target.getAttribute("data-savings-percentage")
      );

      percentage = newPercentage;
      displayText = `Savings: More than ${newPercentage}%`;
    }

    setFilterState({
      isFiltered: true,
      savings: {
        percentage: percentage,
        displayText: displayText,
      },
      vendors: newVendorState,
    });
  };

  const activeFiltersCount = () => {
    let count = 1;

    Object.keys(filterState.vendors).map((vendor) => {
      if (filterState.vendors[vendor].display === true) {
        count += 1;
      }
    });

    return count;
  };

  return (
    <>
      <FilterButton
        onClick={handleClick}
        aria-controls={open ? "filter-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        sx={{
          color: filterState.isFiltered ? "#FFFFFF" : "#878C87",
          background: filterState.isFiltered ? "#878C87" : "#FFFFFF",
        }}
      >
        {isMobile ? (
          <>
            <FilterTextContainer>Filter By</FilterTextContainer>
            {filterState.isFiltered ? (
              <div>({`${activeFiltersCount()}`})</div>
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </>
        ) : (
          <>
            <FilterTextContainer>
              {filterState.savings.displayText}
            </FilterTextContainer>
            <KeyboardArrowDownIcon />
          </>
        )}
      </FilterButton>
      <>
        {isMobile ? (
          <Drawer
            anchor="bottom"
            open={open}
            onClose={applyFilter}
            PaperProps={{ sx: { borderRadius: "16px" } }}
          >
            <List>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text sx={{ fontSize: "var(--size-600)", color: "#878C87" }}>
                  Filter By
                </Text>
                <Text
                  onClick={applyFilter}
                  sx={{ color: "#3E92CC", cursor: "pointer" }}
                >
                  close
                </Text>
              </ListItem>
              <Divider />
              <ListItem>
                <Text sx={{ color: "#878C87" }}>Savings</Text>
              </ListItem>
              <ListItem
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={1}
                  selected={selected}
                >
                  More than 1%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={5}
                  selected={selected}
                >
                  More than 5%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={10}
                  selected={selected}
                >
                  More than 10%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={15}
                  selected={selected}
                >
                  More than 15%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={20}
                  selected={selected}
                >
                  More than 20%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={25}
                  selected={selected}
                >
                  More than 25%
                </SavingsButton>
                <SavingsButton
                  onClick={toggleSavingsSelection}
                  savingsPercentage={30}
                  selected={selected}
                >
                  More than 30%
                </SavingsButton>
              </ListItem>
              <ListItem>
                <Text sx={{ color: "#878C87" }}>Vendors</Text>
              </ListItem>
              <ListItem>
                {Object.keys(filterState.vendors).map((vendor, index) => (
                  <VendorButton
                    key={index}
                    onClick={toggleVendorSelection}
                    vendor={selected.vendors[vendor]}
                  >
                    {transformVendorName(vendor)}
                  </VendorButton>
                ))}
              </ListItem>
              <ListItem
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text
                  onClick={applyFilter}
                  sx={{ color: "#3E92CC", cursor: "pointer" }}
                >
                  Apply filters
                </Text>
                <Text
                  onClick={resetFilter}
                  sx={{ color: "#3E92CC", cursor: "pointer" }}
                >
                  Clear filters
                </Text>
              </ListItem>
            </List>
          </Drawer>
        ) : (
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "sort-menu-button",
            }}
          >
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={1}>
              More than 1%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={5}>
              More than 5%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={10}>
              More than 10%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={15}>
              More than 15%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={20}>
              More than 20%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={25}>
              More than 25%
            </StyledMenuItem>
            <StyledMenuItem onClick={applyFilter} data-savings-percentage={30}>
              More than 30%
            </StyledMenuItem>
          </Menu>
        )}
      </>
    </>
  );
}

function SavingsButton({ onClick, savingsPercentage, selected, children }) {
  return (
    <SelectButton
      data-savings-percentage={savingsPercentage}
      onClick={onClick}
      sx={{
        marginBottom: "8px",
        marginRight: "8px",
        color:
          selected.savingsPercentage === savingsPercentage
            ? "#FFFFFF"
            : "#878C87",
        background:
          selected.savingsPercentage === savingsPercentage
            ? "#878C87"
            : "#FFFFFF",
      }}
    >
      {children}
    </SelectButton>
  );
}

function VendorButton({ onClick, vendor, children }) {
  return (
    <SelectButton
      onClick={onClick}
      sx={{
        marginBottom: "8px",
        marginRight: "8px",
        color: vendor.isSelectedOnMobile ? "#FFFFFF" : "#878C87",
        background: vendor.isSelectedOnMobile ? "#878C87" : "#FFFFFF",
      }}
    >
      {children}
    </SelectButton>
  );
}
